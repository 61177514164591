// src/App.js
import { ConfigProvider, Layout } from 'antd';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import NavMenu from './NavMenu';
import { UserProvider } from './UserContext';
import { AuthLayout } from './layouts/AuthLayout';
import { PushToSendPage } from './pages/agentLeads/pushToSend';
import { AgentSmsPage } from './pages/agentSms';
import { AppointmentReviewPage } from './pages/appointmentReview/page';
import BackfillStatusPage from './pages/client/[client_id]/backfill-status/page';
import PendingVerificationPage from './pages/client/[client_id]/pending-verification/page';
import { ClientSettingsPage } from './pages/client/[client_id]/settings/page';
import VerifySingleAgentPage from './pages/client/[client_id]/verify-single-agent/page';
import VerifyClientPage from './pages/client/[client_id]/verify/page';
import ClientsPage from './pages/client/page';
import ViewConfig from './pages/config/page';
import { ErrorPage } from './pages/error';
import { HealthCheck } from './pages/health';
import { Login } from './pages/login';
import { OnboardingPage } from './pages/onboarding';
import { OnboardingNewPage } from './pages/onboardingNew';
import OnboardingStatusPage from './pages/onboardingStatus';
import ClientSelect from './pages/page';
import { PrivacyStatement } from './pages/privacyStatement';
import { TaskReviewPage } from './pages/taskReview/page';

const { Content } = Layout;

function App(): JSX.Element {
  const apiUrl = process.env.REACT_APP_API_URL || 'localhost:7071';
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#5b1eaf',
          colorInfo: '#5b1eaf',
          fontFamily: 'Roboto',
        },
      }}
    >
      <UserProvider>
        <Router>
          <Layout
            className="App"
            style={{ backgroundColor: '#F7F9FF', minHeight: '100vh' }}
          >
            {/* <Header> */}
            <NavMenu />
            {/* </Header> */}
            <Content style={{ padding: '0 5%' }}>
              <Routes>
                <Route element={<AuthLayout />} path="">
                  <Route
                    element={
                      <>
                        <ClientSelect />
                      </>
                    }
                    path="/"
                  />
                  <Route
                    element={
                      <>
                        <AgentSmsPage apiUrl={apiUrl} />
                      </>
                    }
                    path="/agentSms/:clientId/"
                  />

                  <Route
                    element={
                      <>
                        <TaskReviewPage apiUrl={apiUrl} />
                      </>
                    }
                    path="/task-review/:clientId/:recordingID"
                  />
                  <Route
                    element={
                      <>
                        <ViewConfig apiUrl={apiUrl} />
                      </>
                    }
                    path="/view-config/"
                  />
                  <Route
                    element={
                      <>
                        <AppointmentReviewPage apiUrl={apiUrl} />
                      </>
                    }
                    path="/appointment-review/:clientId/:recordingID"
                  />
                  <Route
                    element={
                      <>
                        <OnboardingPage apiUrl={apiUrl} />
                      </>
                    }
                    path="/onboarding/:clientId"
                  />
                  <Route
                    element={
                      <>
                        <OnboardingNewPage apiUrl={apiUrl} />
                      </>
                    }
                    path="/onboardingNew/:clientId"
                  />
                  <Route path="/client/">
                    <Route
                      element={
                        <>
                          <ClientsPage apiUrl={apiUrl} />
                        </>
                      }
                      path=""
                    />
                    <Route
                      element={
                        <>
                          <ClientSettingsPage apiUrl={apiUrl} />
                        </>
                      }
                      path=":clientId/settings"
                    />
                    <Route
                      element={
                        <>
                          <VerifyClientPage apiUrl={apiUrl} />
                        </>
                      }
                      path=":clientId/verify"
                    />
                    <Route
                      element={
                        <>
                          <PendingVerificationPage apiUrl={apiUrl} />
                        </>
                      }
                      path=":clientId/pending-verification"
                    />
                    <Route
                      element={
                        <>
                          <VerifySingleAgentPage apiUrl={apiUrl} />
                        </>
                      }
                      path=":clientId/verify-single-agent"
                    />
                    <Route
                      element={
                        <>
                          <BackfillStatusPage apiUrl={apiUrl} />
                        </>
                      }
                      path=":clientId/backfill-status"
                    />
                  </Route>
                  <Route
                    element={
                      <>
                        <OnboardingStatusPage apiUrl={apiUrl} />
                      </>
                    }
                    path="/onboarding/status/:clientId"
                  />
                </Route>
                {/* Unauthenticated routes */}
                <Route
                  element={
                    <>
                      <PrivacyStatement apiUrl={apiUrl} />
                    </>
                  }
                  path="/privacyStatement"
                />

                <Route
                  element={
                    <>
                      <ErrorPage apiUrl={apiUrl} />
                    </>
                  }
                  path="/error"
                />
                <Route
                  element={<HealthCheck apiUrl={apiUrl} />}
                  path="/health"
                />
                <Route
                  element={
                    <>
                      <PushToSendPage apiUrl={apiUrl} />
                    </>
                  }
                  path="/leads2/:clientId/:agentId/pts"
                />
                <Route
                  element={<Login apiUrl={apiUrl} />}
                  path="/login/:agentId"
                />
              </Routes>
            </Content>
          </Layout>
        </Router>
      </UserProvider>
    </ConfigProvider>
  );
}

export default App;
