import React from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { SignInButton } from '../SignInButton';

const ClientSelect = () => {
  const { accounts } = useMsal();

  return (
    <div>
      <AuthenticatedTemplate>
        <p>
          Welcome
          {accounts[0] ? ' ' + accounts[0].name : 'Unknown'}
        </p>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <h5>
          <center>Please sign-in to see your information.</center>
          <SignInButton />
        </h5>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default ClientSelect;
