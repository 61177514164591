import { ClientConfig } from '@/types';
import { Alert, Button, Spin, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../../../UserContext';

const { Title, Text } = Typography;

interface VerificationStatus {
  recordingsEnabled: boolean;
  smsAccess: boolean;
}

type Client = {
  id: string;
  friendlyName: string;
  crmType: string;
};

const PendingVerificationPage: React.FC<{ apiUrl: string }> = ({ apiUrl }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { clientId } = useParams<{ clientId: string }>();
  const user = useContext(UserContext);
  const [loadingBackfill, setLoadingBackfill] = useState<boolean>(false);
  const [loadingVerification, setLoadingVerification] = useState<boolean>(true);
  const [loadingClient, setLoadingClient] = useState<boolean>(true);
  const [verificationError, setVerificationError] = useState<string | null>(
    null,
  );
  const [client, setClient] = useState<Client | null>(null);

  // Get state passed from verification page
  const locationState = location.state || {
    recordingsEnabled: false,
    smsAccess: false,
    friendlyName: '',
    crmType: '',
  };

  const [verificationStatus, setVerificationStatus] = useState<{
    recordingsEnabled: boolean;
    smsAccess: boolean;
  }>({
    recordingsEnabled: locationState.recordingsEnabled,
    smsAccess: locationState.smsAccess,
  });

  useEffect(() => {
    if (user) {
      void fetchClient();
      void fetchVerificationStatus();
    }
    console.log('Client ID:', clientId);
    console.log('user:', user);
  }, [clientId, user]);

  const fetchClient = async () => {
    if (!user) {
      return;
    }

    try {
      setLoadingClient(true);
      const response = await fetch(`${apiUrl}/api/clients/${clientId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`${response.status} - ${await response.text()}`);
      }

      const clientData: ClientConfig = await response.json();
      setClient({
        id: clientData.id,
        friendlyName: clientData.friendly_name,
        crmType: clientData.crm_type,
      });
    } catch (error) {
      console.error('Error fetching client:', error);
    } finally {
      setLoadingClient(false);
    }
  };

  const fetchVerificationStatus = async () => {
    if (!user) {
      return;
    }

    try {
      setLoadingVerification(true);
      const response = await fetch(`${apiUrl}/api/clients/${clientId}/verify`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`${response.status} - ${await response.text()}`);
      }

      const data = await response.json();

      // Update with latest verification status data from API
      setVerificationStatus({
        recordingsEnabled:
          data.isRecordingEnabled !== undefined
            ? data.isRecordingEnabled
            : locationState.recordingsEnabled,
        smsAccess:
          data.isSmsEnabled !== undefined
            ? data.isSmsEnabled
            : locationState.smsAccess,
      });
    } catch (error) {
      console.error('Error fetching verification status:', error);
      setVerificationError(
        'Failed to fetch verification status. Using data from previous step.',
      );

      // Fallback to location state if verification API fails
      setVerificationStatus({
        recordingsEnabled: locationState.recordingsEnabled,
        smsAccess: locationState.smsAccess,
      });
    } finally {
      setLoadingVerification(false);
    }
  };

  const getSmsMessage = () => {
    return `Hello,
    I would like to request the SMS content be enabled for API requests for ${client?.friendlyName || ''}.  My understanding is this is done via a feature flag on the backend.  The account owner is aware of this request and understands that their approval will be required.
    Please let us know if there are any additional steps we need to take to facilitate this process.
    Thank you for you assistance!`;
  };

  const handleCopyMessage = () => {
    navigator.clipboard.writeText(getSmsMessage());
  };

  const handleStartBackfill = async () => {
    if (user == null) {
      return;
    }
    try {
      setLoadingBackfill(true);
      const response = await fetch(
        `${apiUrl}/api/clients/${clientId}/backfill`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.accessToken}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error(`${response.status} - ${await response.text()}`);
      }
      navigate(`/client/${clientId}/backfill-status`);
    } catch (error) {
      console.error('Error fetching backfill:', error);
      alert(error);
    } finally {
      setLoadingBackfill(false);
    }
  };

  if (loadingVerification || loadingClient) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin size="large" />
        <p>Loading verification status...</p>
      </div>
    );
  }

  return (
    <div>
      <Title level={1}>Onboarding - {client?.friendlyName || 'Client'}</Title>
      <div
        style={{
          backgroundColor: '#FFFFFF',
          padding: 20,
          borderRadius: 8,
          maxWidth: 800,
          margin: '0 auto',
        }}
      >
        <Title level={2}>Account Verification Status</Title>

        {verificationError && (
          <Alert
            message={verificationError}
            showIcon
            style={{ marginBottom: '20px' }}
            type="warning"
          />
        )}

        <div style={{ margin: '20px 0' }}>
          <div style={{ margin: '10px 0' }}>
            Recordings:{' '}
            <Text
              strong
              type={verificationStatus.recordingsEnabled ? 'success' : 'danger'}
            >
              {verificationStatus.recordingsEnabled ? 'Enabled' : 'Disabled'}
            </Text>
          </div>
          <div style={{ margin: '10px 0' }}>
            SMS Access:{' '}
            <Text
              strong
              type={verificationStatus.smsAccess ? 'success' : 'danger'}
            >
              {verificationStatus.smsAccess ? 'Enabled' : 'Disabled'}
            </Text>
          </div>
        </div>

        {client &&
          (client.crmType === 'sierra' || client.crmType === 'kvcore') && (
            <Alert
              message="Note: We currently do not support automated checking of recording or SMS status for Sierra and KVCore. Please manually review if not done so already, then you can run the backfill."
              showIcon
              style={{ margin: '20px 0' }}
              type="info"
            />
          )}

        {!verificationStatus.smsAccess && (
          <div style={{ margin: '20px 0' }}>
            <Title level={3}>Instructions for SMS Access</Title>
            <p>Send the following message from Follow Up Boss Login:</p>
            <pre
              style={{ border: '1px solid #ccc', borderRadius: 8, padding: 10 }}
            >
              {getSmsMessage()}
            </pre>
            <Button onClick={handleCopyMessage} type="primary">
              Copy Message
            </Button>
          </div>
        )}

        <div style={{ marginTop: '30px', display: 'flex', gap: '10px' }}>
          <Button
            disabled={!verificationStatus.smsAccess}
            loading={loadingBackfill}
            onClick={handleStartBackfill}
            type="primary"
          >
            Start Backfill
          </Button>

          <Button onClick={() => navigate(`/client/${clientId}/verify`)}>
            Back to Verification
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PendingVerificationPage;
