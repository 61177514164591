import { useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from '../../UserContext';
import { OnboardingFlow } from '../../types';
import { useParams } from 'react-router-dom';
import { Button, Checkbox, Col, Divider, Flex, Input, Row, Space } from 'antd'; // Import Ant Design components
import {
  CheckCircleTwoTone,
  RedoOutlined,
  SaveOutlined,
} from '@ant-design/icons'; // Import Ant Design icons
import { AlertBanner } from '../../AlertBanner';

interface UpdateData {
  greeting_message?: string;
  phone_override?: string;
  is_isa?: boolean;
}

export const OnboardingPage = ({ apiUrl }: { apiUrl: string }) => {
  const user = useContext(UserContext);
  const [onboardingData, setOnboardingData] = useState<
    Record<string, OnboardingFlow>
  >({});
  const [updatedData, setUpdatedData] = useState<Record<string, UpdateData>>(
    {},
  );
  const [loadingSendAll, setLoadingSendAll] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const { clientId } = useParams();
  const fetchOnboardingData = async () => {
    try {
      if (!user?.accessToken) {
        console.error('No access token available');
        return;
      }

      const response = await fetch(
        `${apiUrl}/api/onboarding/flow/${clientId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.accessToken}`,
          },
        },
      );
      const jsonData: Array<OnboardingFlow> = await response.json();
      setOnboardingData(
        jsonData.reduce((acc: Record<string, OnboardingFlow>, data) => {
          acc[data.id] = data;
          return acc;
        }, {}),
      );
      console.log(jsonData);
    } catch (err) {
      console.error('An error occurred while fetching the agents:', err);
    }
  };
  useEffect(() => {
    fetchOnboardingData().then().catch(console.log);
  }, []);

  const handleUpdateData = (key: string, id: string, value: any) => {
    setUpdatedData(prev => ({ ...prev, [id]: { ...prev[id], [key]: value } }));
  };

  const savePhoneOverride = async (flow_id: string) => {
    // Implement save logic here, e.g., API call to save the phone override
    const override = updatedData[flow_id]?.phone_override;
    const flow = onboardingData[flow_id];
    if (!flow) {
      setError('Flow not found');
      return;
    }
    if (override == null || override === '') {
      setError('Phone override cannot be empty');
      return;
    }
    try {
      // Save logic here
      if (!user?.accessToken) {
        throw new Error('No access token available');
      }
      const response = await fetch(`${apiUrl}/api/agent/${flow.agent_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({ phone_override: override }),
      });
      if (!response.ok) {
        throw new Error(
          `Failed to save phone override: ${response.statusText}`,
        );
      }
      const jsonData = await response.json();
      const data = { ...onboardingData };
      data[flow.id].agent.phone_override = override;
      setOnboardingData(data);
      handleUpdateData('phone_override', flow.id, '');
    } catch (err) {
      setError(`${err}`);
      return;
    }
  };

  const saveGreetingMessage = async (flow_id: string) => {
    // Implement save logic here, e.g., API call to save the phone override
    const override = updatedData[flow_id]?.greeting_message;
    const flow = onboardingData[flow_id];
    if (!flow) {
      setError('Flow not found');
      return;
    }
    if (override == null || override === '') {
      setError('Greeting Message cannot be empty');
      return;
    }
    try {
      // Save logic here
      if (!user?.accessToken) {
        throw new Error('No access token available');
      }
      const response = await fetch(`${apiUrl}/api/onboarding/flow/${flow_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({ greeting_message: override }),
      });
      if (!response.ok) {
        throw new Error(
          `Failed to save greeting message: ${response.statusText}`,
        );
      }
      //   const jsonData = await response.json();
      const data = { ...onboardingData };
      data[flow.id].greeting_message = override;
      setOnboardingData(data);
      handleUpdateData('greeting_message', flow.id, '');
    } catch (err) {
      setError(`${err}`);
      return;
    }
  };

  const redoGreetingMessage = async (flow_id: string) => {
    const flow = onboardingData[flow_id];
    try {
      if (!user?.accessToken) {
        throw new Error('No access token available');
      }
      const response = await fetch(
        `${apiUrl}/api/onboarding/flow/${flow.agent_id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.accessToken}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error(`Failed to redo flow: ${response.statusText}`);
      }
      const jsonData = await response.json();
      const data = { ...onboardingData };
      data[flow.id] = { ...data[flow.id], ...jsonData };
      setOnboardingData(data);
    } catch (err) {
      setError(`${err}`);
      return;
    }
  };

  const sendGreetingMessage = async (flow_id: string) => {
    try {
      await _sendGreetingMessage(flow_id);
    } catch (err) {
      setError(`${err}`);
      return;
    }
  };

  const _sendGreetingMessage = async (flow_id: string) => {
    const flow = onboardingData[flow_id];
    if (!user?.accessToken) {
      throw new Error('No access token available');
    }
    const response = await fetch(
      `${apiUrl}/api/onboarding/send-greeting/${flow.agent_id}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        },
      },
    );
    if (!response.ok) {
      throw new Error(
        `Failed to send greeting for ${flow.agent.first_name} ${flow.agent.last_name}: ${response.statusText}`,
      );
    }
    const data = { ...onboardingData };
    const jsonData = await response.json();
    data[flow.id].greeting_sent_at = jsonData.onboarding_flow.greeting_sent_at;
    setOnboardingData(data);
    const message =
      'I will call you in a couple minutes mins to introduce myself! Prefer another time? Let me know!';
    if (!user?.accessToken) {
      throw new Error('No access token available');
    }
    const responseSecond = await fetch(`${apiUrl}/api/calls/sms`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.accessToken}`,
      },
      body: JSON.stringify({
        agentId: flow.agent_id,
        message: message,
        meta: { type: 'onboarding' },
      }),
    });
    if (!responseSecond.ok) {
      throw new Error(
        `Failed to send call SMS to ${flow.agent.first_name} ${flow.agent.last_name}:: ${responseSecond.statusText}`,
      );
    }
  };

  const sendAll = async () => {
    setLoadingSendAll(true);
    const limit = 10;
    const flowIds = Object.keys(onboardingData);
    const exceptions = [];
    for (let i = 0; i < flowIds.length; i += limit) {
      const chunk = flowIds.slice(i, i + limit);
      const results = await Promise.allSettled(
        chunk.map(flow_id => sendGreetingMessage(flow_id)),
      );
      for (const result of results) {
        if (result.status === 'rejected') {
          exceptions.push(result.reason);
        }
      }
    }
    if (exceptions.length > 0) {
      setError(`Failed to send greeting messages: ${exceptions.join('\n')}`);
    }
    setLoadingSendAll(false);
  };

  const callAll = async () => {
    setLoadingSendAll(true);
    const limit = 10;
    const flowIds = Object.keys(onboardingData);
    const exceptions = [];
    for (let i = 0; i < flowIds.length; i += limit) {
      const chunk = flowIds.slice(i, i + limit);
      const results = await Promise.allSettled(
        chunk.map(flow_id => _callAgent(flow_id)),
      );
      for (const result of results) {
        if (result.status === 'rejected') {
          exceptions.push(result.reason);
        }
      }
      await new Promise(resolve => setTimeout(resolve, 5000)); // Add a 5 second delay between each loop
    }
    if (exceptions.length > 0) {
      setError(`Failed to call: ${exceptions.join('\n')}`);
    }
    setLoadingSendAll(false);
  };

  const _callAgent = async (flow_id: string) => {
    const flow = onboardingData[flow_id];
    if (!user?.accessToken) {
      throw new Error('No access token available');
    }
    const response = await fetch(
      `${apiUrl}/api/onboarding/call/${flow.agent_id}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        },
      },
    );
    if (!response.ok) {
      throw new Error(
        `Failed to call ${flow.agent.first_name} ${flow.agent.last_name}: ${response.statusText}`,
      );
    }
  };

  const callAgent = async (flow_id: string) => {
    try {
      await _callAgent(flow_id);
    } catch (err) {
      setError(`${err}`);
      return;
    }
  };

  const saveIsIsa = async (flow_id: string) => {
    const flow = onboardingData[flow_id];
    if (!flow) {
      setError('Flow not found');
      return;
    }
    const is_isa = !flow.agent.is_isa;
    try {
      if (!user?.accessToken) {
        throw new Error('No access token available');
      }
      const response = await fetch(`${apiUrl}/api/agent/${flow.agent_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({ is_isa: is_isa }),
      });
      if (!response.ok) {
        throw new Error(`Failed to save ISA status: ${response.statusText}`);
      }
      const data = { ...onboardingData };
      data[flow.id].agent.is_isa = is_isa;
      setOnboardingData(data);
    } catch (err) {
      setError(`${err}`);
      return;
    }
  };

  const filteredData = useMemo(
    () =>
      Object.values(onboardingData).filter(data =>
        `${data.agent.first_name} ${data.agent.last_name}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase()),
      ),
    [onboardingData, searchTerm],
  );
  return (
    <div>
      <AlertBanner error={error} setError={setError} />
      <h2>Onboarding for {clientId}</h2>
      <Flex>
        <div style={{ maxWidth: '300px', width: '20vw' }}>
          <Input
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search by name"
            style={{ marginBottom: '16px' }}
          />
        </div>
        <Button
          loading={loadingSendAll}
          onClick={() => void sendAll()}
          style={{ marginLeft: 10 }}
        >
          SendAllGreetings
        </Button>
        <Button
          loading={loadingSendAll}
          onClick={() => void callAll()}
          style={{ marginLeft: 10 }}
        >
          CallAll
        </Button>
      </Flex>
      <div>
        <Row gutter={[16, 16]}>
          <Col lg={12} md={24}>
            <Row>
              <Col lg={4} md={8} xs={8}>
                <strong>Name</strong>
              </Col>
              <Col lg={1} md={2} xs={2}>
                <strong>ISA</strong>
              </Col>
              <Col lg={3} md={6} xs={6}>
                <strong>Flow Status</strong>
              </Col>
              <Col lg={4} md={8} xs={8}>
                <strong>Phone</strong>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <strong>Phone Override</strong>
              </Col>
              <Col lg={6} md={12} xs={12}>
                <strong>Email</strong>
              </Col>
            </Row>
          </Col>
          <Col lg={12} md={24}>
            <Row>
              <Col span={20}>
                <strong>Greeting Message</strong>
              </Col>
              <Col span={4}>
                <strong>Lead Bio</strong>
              </Col>
            </Row>
          </Col>
        </Row>
        {filteredData.map((data, index) => (
          <>
            <Divider />
            <Row gutter={[16, 16]} key={index}>
              <Col lg={12} md={24}>
                <Row>
                  <Col lg={4} md={8} xs={8}>
                    <span>
                      {data.agent.first_name} {data.agent.last_name}
                    </span>
                  </Col>
                  <Col lg={1} md={2} xs={2}>
                    <Checkbox
                      checked={data.agent.is_isa}
                      onChange={() => void saveIsIsa(data.id)}
                    />
                  </Col>
                  <Col lg={3} md={6} xs={6}>
                    {data.stage}
                  </Col>
                  <Col lg={4} md={8} xs={8}>
                    <span>{data.agent.phone}</span>
                  </Col>
                  <Col lg={6} md={12} xs={12}>
                    <span>
                      <Input
                        onChange={e =>
                          handleUpdateData(
                            'phone_override',
                            data.id,
                            e.target.value,
                          )
                        }
                        style={{ width: '70%' }}
                        value={
                          updatedData[data.id]?.phone_override ||
                          data.agent.phone_override ||
                          ''
                        }
                      />
                      <Button
                        disabled={
                          updatedData[data.id]?.phone_override == null ||
                          updatedData[data.id]?.phone_override === ''
                        }
                        icon={<SaveOutlined />}
                        onClick={() => void savePhoneOverride(data.id)}
                        style={{ marginLeft: '8px' }}
                      />
                    </span>
                  </Col>
                  <Col lg={6} md={12} xs={12}>
                    <span>{data.agent.email}</span>
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Space>
                    <Button onClick={() => void sendGreetingMessage(data.id)}>
                      Resend
                    </Button>
                    <Button onClick={() => void callAgent(data.id)}>
                      Call now
                    </Button>
                  </Space>
                </Row>
              </Col>

              <Col lg={8} md={16} xs={16}>
                <div>
                  <Row>
                    <Col span={20}>
                      <Input.TextArea
                        onChange={e =>
                          handleUpdateData(
                            'greeting_message',
                            data.id,
                            e.target.value,
                          )
                        }
                        rows={5}
                        style={{ width: '100%' }}
                        value={
                          updatedData[data.id]?.greeting_message ||
                          data.greeting_message ||
                          ''
                        }
                      />
                    </Col>
                    <Col span={4}>
                      <Space direction="vertical">
                        <Button
                          icon={<RedoOutlined />}
                          onClick={() => void redoGreetingMessage(data.id)}
                        />
                        <Button
                          disabled={
                            updatedData[data.id]?.greeting_message == null ||
                            updatedData[data.id]?.greeting_message === ''
                          }
                          icon={<SaveOutlined />}
                          onClick={() => void saveGreetingMessage(data.id)}
                          //   style={{ marginLeft: '8px' }}
                        />
                        {data.greeting_sent_at && (
                          <CheckCircleTwoTone twoToneColor="#52c41a" />
                        )}
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={4} md={4} xs={8}>
                {data.str_bio && (
                  <div style={{ maxHeight: 200, overflow: 'auto' }}>
                    <a href={data.crm_link}>link</a>
                    <br></br>
                    <pre style={{ textAlign: 'left' }}>{data.str_bio}</pre>
                  </div>
                )}
              </Col>
            </Row>
          </>
        ))}
      </div>
    </div>
  );
};
