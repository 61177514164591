import {
  Button,
  Checkbox,
  Form,
  Input,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import {
  FrownOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Stage } from '../../types/stages';
import { ColumnsType } from 'antd/es/table';
import type { DragEndEvent } from '@dnd-kit/core';
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

interface StageFormData {
  stages: Stage[];
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {children}
    </tr>
  );
};

export function StagesConfig({
  stages,
  setStages,
  clientId,
  apiUrl,
  accessToken,
  setError,
}: {
  stages: Stage[];
  setStages: (stages: Stage[]) => void;
  clientId: string;
  apiUrl: string;
  accessToken: string;
  setError: (error: string | undefined) => void;
}) {
  const [form] = Form.useForm<StageFormData>();
  const [loading, setLoading] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    form.setFieldsValue({ stages });
  }, [stages, form]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = stages.findIndex(i => i.name === active.id);
      const overIndex = stages.findIndex(i => i.name === over?.id);
      const newStages = arrayMove(stages, activeIndex, overIndex);
      setStages(newStages);
      form.setFieldsValue({ stages: newStages });
      setIsTouched(true);
    }
  };

  const handleFinish = async (values: StageFormData) => {
    setLoading(true);
    setError(undefined);

    try {
      const response = await fetch(
        `${apiUrl}/api/settings/${clientId}/stages`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(values.stages),
        },
      );

      if (!response.ok) {
        throw new Error('Failed to update stages');
      }

      setStages(values.stages);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const renderTitle = (title: string, tooltip: string) => (
    <Space>
      {title}
      <Typography.Text style={{ fontSize: '14px' }} type="secondary">
        <QuestionCircleOutlined style={{ cursor: 'help' }} title={tooltip} />
      </Typography.Text>
    </Space>
  );

  const columns: ColumnsType<Stage> = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />,
    },
    {
      title: renderTitle(
        'Valid for AI',
        'Whether this stage can be processed by AI',
      ),
      dataIndex: 'is_valid_for_ai',
      key: 'is_valid_for_ai',
      width: '100px',
      align: 'center',
      render: (value: boolean, record: Stage, index: number) => {
        if (record.is_deleted_from_CRM) {
          return (
            <Space>
              <Tooltip
                title={
                  <div>
                    <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                      Stage Removed from CRM
                    </div>
                    <div>This stage no longer exists in your CRM system.</div>
                    <div>When you save:</div>
                    <ul style={{ marginTop: '4px', paddingLeft: '16px' }}>
                      <li>It will be removed from recommended stages</li>
                      <li>
                        If a similar stage exists, settings will be transferred
                      </li>
                    </ul>
                  </div>
                }
              >
                <div>
                  <FrownOutlined
                    style={{ color: '#ff4d4f', fontSize: '16px' }}
                  />
                  <span
                    style={{
                      marginLeft: '4px',
                      color: '#ff4d4f',
                      fontSize: '12px',
                    }}
                  >
                    Deleted
                  </span>
                </div>
              </Tooltip>
              <Form.Item
                hidden
                name={['stages', index, 'is_deleted_from_CRM']}
                valuePropName="checked"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['stages', index, 'is_valid_for_ai']}
                style={{ margin: 0 }}
                valuePropName="unchecked"
              >
                <Checkbox
                  disabled
                  style={{ opacity: 0.5, cursor: 'not-allowed' }}
                />
              </Form.Item>
            </Space>
          );
        }
        return (
          <>
            <Form.Item
              name={['stages', index, 'is_valid_for_ai']}
              style={{ margin: 0, textAlign: 'center' }}
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
            <Form.Item
              hidden
              initialValue={false}
              name={['stages', index, 'is_deleted_from_CRM']}
            >
              <Input />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: 'Stage Name',
      dataIndex: 'name',
      key: 'name',
      width: '200px',
      render: (name: string, record: Stage, index: number) => (
        <Form.Item
          name={['stages', index, 'name']}
          style={{ margin: 0, textAlign: 'center' }}
        >
          <div
            style={{
              opacity: record.is_deleted_from_CRM ? 0.65 : 1,
              textDecoration: record.is_deleted_from_CRM
                ? 'line-through'
                : 'none',
            }}
          >
            {name}
          </div>
        </Form.Item>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '300px',
      render: (description: string, record: Stage, index: number) => (
        <Form.Item
          name={['stages', index, 'description']}
          style={{ margin: 0, textAlign: 'center' }}
        >
          <Input />
        </Form.Item>
      ),
    },
    {
      title: renderTitle(
        'Valid from Unordered',
        'Whether this stage can be reached from an unordered stage',
      ),
      dataIndex: 'is_valid_from_unordered',
      key: 'is_valid_from_unordered',
      width: '120px',
      align: 'center',
      render: (_: boolean, record: Stage, index: number) => (
        <Form.Item
          name={['stages', index, 'is_valid_from_unordered']}
          style={{ margin: 0, textAlign: 'center' }}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      ),
    },
    {
      title: renderTitle(
        'Is Unordered',
        'Whether this stage is unordered (not part of the normal flow)',
      ),
      dataIndex: 'is_unordered',
      key: 'is_unordered',
      width: '100px',
      align: 'center',
      render: (_: boolean, record: Stage, index: number) => (
        <div
          style={{
            opacity: record.is_deleted_from_CRM ? 0.65 : 1,
            textDecoration: record.is_deleted_from_CRM
              ? 'line-through'
              : 'none',
          }}
        >
          <Form.Item
            name={['stages', index, 'is_unordered']}
            style={{ margin: 0, textAlign: 'center' }}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </div>
      ),
    },
  ];

  return (
    <div
      style={{ textAlign: 'left', backgroundColor: 'white', padding: '20px' }}
    >
      <Typography.Title level={2}>Stage Configuration</Typography.Title>
      <Typography.Paragraph>
        Configure the stages for your onboarding process. If valid for AI is not
        checked, all data will be lost on save
      </Typography.Paragraph>
      <Form
        disabled={loading}
        form={form}
        initialValues={{ stages }}
        onFinish={handleFinish}
        onValuesChange={() => setIsTouched(true)}
      >
        <DndContext
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={onDragEnd}
          sensors={sensors}
        >
          <SortableContext
            items={stages.map(i => i.name)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              columns={columns}
              components={{
                body: {
                  row: Row,
                },
              }}
              dataSource={stages}
              pagination={false}
              rowKey="name"
            />
          </SortableContext>
        </DndContext>
        <div style={{ marginTop: '16px', textAlign: 'right' }}>
          <Button
            disabled={!isTouched}
            htmlType="submit"
            loading={loading}
            type="primary"
          >
            Save Stage Configuration
          </Button>
        </div>
      </Form>
    </div>
  );
}
