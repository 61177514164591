import { ClientConfig } from '@/types';
import {
  Alert,
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Typography,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../../../UserContext';

const { Title } = Typography;

type Client = {
  id: string;
  clientName: string;
  friendlyName: string;
  clientState: string;
  crmType: string;
  clientId: number;
};

interface FormValues {
  clientName: string;
  email?: string;
  username?: string;
  accountPassword?: string;
  apiKey: string;
  siteName?: string;
  subdomain?: string;
  isSmallTeam: boolean;
}

const VerifyClientPage: React.FC<{ apiUrl: string }> = ({ apiUrl }) => {
  const [isSmallTeam, setIsSmallTeam] = useState(false);
  const [form] = Form.useForm();

  const getSmsMessage = (friendlyName: string | undefined) => {
    return `Hello,
    I would like to request the SMS content be enabled for API requests for ${friendlyName}.  My understanding is this is done via a feature flag on the backend.  The account owner is aware of this request and understands that their approval will be required.
    Please let us know if there are any additional steps we need to take to facilitate this process.
    Thank you for you assistance!`;
  };

  const [loadingVerify, setLoadingVerify] = useState<boolean>(false);
  const [client, setClient] = useState<Client>();
  const [clientNameValue, setClientNameValue] = useState<string>('');
  const [validationError, setValidationError] = useState<string | null>(null);
  const user = useContext(UserContext);

  const { clientId } = useParams<{
    clientId: string;
  }>();
  const navigate = useNavigate();

  const handleVerify = async (values: FormValues) => {
    const data = {
      clientName: values.clientName,
      email: values.email,
      username: values.username,
      password: values.accountPassword,
      apiKey: values.apiKey,
      siteName: values.siteName,
      subdomain: values.subdomain,
      isSmallTeam: values.isSmallTeam,
    };
    if (user == null) {
      return;
    }

    try {
      setValidationError('');
      setLoadingVerify(true);
      const response = await fetch(`${apiUrl}/api/clients/${clientId}/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`${response.status} - ${await response.text()}`);
      }

      let recordingsEnabled = true;
      let smsAccess = true;

      if (response.status !== 200) {
        const data = await response.json();
        recordingsEnabled = data.isRecordingEnabled;
        smsAccess = data.isSmsEnabled;
      }
      navigate(`/client/${clientId}/pending-verification`, {
        state: {
          recordingsEnabled,
          smsAccess,
        },
      });
    } catch (error) {
      setValidationError(`Failed to verify client. ${error}`);
    } finally {
      setLoadingVerify(false);
    }
  };

  useEffect(() => {
    const fetchClient = async () => {
      if (user == null) {
        return;
      }
      try {
        const client_response = await fetch(
          `${apiUrl}/api/clients/${clientId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user.accessToken}`,
            },
          },
        );
        if (!client_response.ok) {
          throw new Error('Failed to fetch clients');
        }
        const client_data: ClientConfig = await client_response.json();
        if (client_data.name) {
          setClientNameValue(client_data.name);
        } else {
          setClientNameValue(
            client_data.friendly_name.toLowerCase().replaceAll(' ', '_'),
          );
        }
        setClient({
          id: client_data.id,
          clientName: client_data.name,
          friendlyName: client_data.friendly_name,
          clientState: client_data.client_state,
          crmType: client_data.crm_type,
          clientId: client_data.client_id,
        });
      } catch (error) {
        await message.error('Failed to load client. Please try again later.');
        console.error('Error fetching clients:', error);
      }
    };
    void fetchClient();
  }, [apiUrl, user?.accessToken]);

  const formatCrmType = (crmType: string | undefined) => {
    switch (crmType) {
      case 'fub':
        return 'Follow Up Boss';
      case 'sierra':
        return 'Sierra';
      case 'kvcore':
        return 'KVCore';
      default:
        return crmType;
    }
  };

  return (
    <div>
      <Title level={1}>
        Onboarding - Create a New {formatCrmType(client?.crmType)} Client
      </Title>
      <div
        style={{
          backgroundColor: '#FFFFFF',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          borderRadius: 8,
        }}
      >
        <div style={{ padding: 20, margin: '3px' }}>
          <Title level={2}>Instructions</Title>
          {client?.crmType === 'fub' ? (
            <ol style={{ textAlign: 'left' }}>
              <li>
                Ask client for Admin Follow Up Boss Account with email:
                <br />
                <Typography.Text strong={true}>
                  api-intrategions+{clientNameValue}@housewhisper.ai
                </Typography.Text>
                <br />
                Requires:{' '}
                <Typography.Text strong={true}>
                  api-intrategions+
                </Typography.Text>{' '}
                <br />
                <Typography.Text strong={true}>
                  {clientNameValue}
                </Typography.Text>{' '}
                does not need to be a exact match
              </li>
              <li>
                Login to the account and configure:
                <ul>
                  <li>Name: HouseWhisper AI</li>
                  <li>Phone Number: (833) 242-1694</li>
                  <li>
                    User Photo:{' '}
                    <img
                      alt="HouseWhisper AI"
                      src="/housewhisper_account_image.jpg"
                      style={{ width: '100px', height: '100px' }}
                    />
                  </li>
                  <li>Set the time zone to the clients time zone</li>
                  <li>Make sure to save at the bottom of the account page</li>
                </ul>
              </li>
              <li>
                Generate API Key:
                <ol>
                  <li>Click Admin, then click API Keys and Lead Emails</li>
                  <li>
                    Click &quot;Create API Key&quot; and name it{' '}
                    <Typography.Text strong={true}>
                      housewhisper-prod
                    </Typography.Text>
                    .
                  </li>
                </ol>
              </li>
            </ol>
          ) : client?.crmType === 'sierra' ? (
            <ol style={{ textAlign: 'left' }}>
              <li>
                Ask the Client for a Manager level Sierra Interactive Account
                with the email:
                <br />
                <Typography.Text strong={true}>
                  api-intrategions+{clientNameValue}@housewhisper.ai
                </Typography.Text>
                <br />
                Requires:{' '}
                <Typography.Text strong={true}>
                  &quot;api-intrategions+&quot;
                </Typography.Text>{' '}
                <br />
                <Typography.Text italic={true}>
                  {clientNameValue}
                </Typography.Text>{' '}
                does not need to be a exact match
                <br />
                <Typography.Text>
                  Additional Permisions they need to set:
                </Typography.Text>
                <br />
                <ul>
                  <li>Add API integration Permissions</li>
                  <li>Add Emulate User Permissions</li>
                </ul>
              </li>
              <li>
                Wait for an email from Sierra with the subject{' '}
                <Typography.Text italic={true}>
                  Your New Account at Sierra Interactive
                </Typography.Text>
                . Click on the{' '}
                <Typography.Text italic={true}>Get Started</Typography.Text>{' '}
                link to set up the account.
              </li>
              <li>
                After logging in to Sierra, edit the User Profile, on the top
                right of the page and change:
                <ul>
                  <li>First Name: HouseWhisper</li>
                  <li>Last Name: AI</li>
                  <li>Phone Number: (833) 242-1694</li>
                  <li>
                    User Photo:{' '}
                    <img
                      alt="HouseWhisper AI"
                      src="/housewhisper_account_image.jpg"
                      style={{ width: '100px', height: '100px' }}
                    />
                    <br />
                    <Typography.Text italic={true}>
                      (Right click and download this image for uploading onto
                      the CRM as the user photo)
                    </Typography.Text>
                  </li>
                  <li>
                    Securely keep a copy of the username and password until
                    onboarding is complete. You may want to change the password
                    to something more secure
                  </li>
                  <li>Turn off any unneeded Sierra email notifications.</li>
                  <li>Make sure to save the profile when done.</li>
                </ul>
              </li>
              <li>
                Get the API Key:
                <ol>
                  <li>
                    Click the cog wheel on the top of the page, then click
                    Integrations.
                  </li>
                  <li>On the next page, click Sierra Interactive.</li>
                  <li>
                    Create the API key if not yet created, otherwise use the one
                    that is set.
                  </li>
                </ol>
              </li>
              <li>
                To Fill out:
                <ol>
                  <li>
                    Client Name - name to use internally, should be lower case
                    with no spaces
                  </li>
                  <li>API Key - API key you got above</li>
                  <li>
                    Is Small Team - if it is a small team where they will not
                    create our own manager account
                  </li>
                  {!isSmallTeam && (
                    <>
                      <li>
                        Account Username - the username used to login to Sierra,
                        get it from{' '}
                        <Typography.Text italic={true}>
                          Your New Account at Sierra Interactive
                        </Typography.Text>{' '}
                        email or from the profile
                      </li>
                      <li>
                        Account Password - the password used to login to Sierra
                      </li>
                      <li>
                        Site Name - Name of the clients site, get it from{' '}
                        <Typography.Text italic={true}>
                          Your New Account at Sierra Interactive
                        </Typography.Text>{' '}
                        email
                      </li>
                      <li>
                        Subdomain - the part of the of the Sierra URL before
                        sierrainteractivedev, for example: {''}
                        <Typography.Text italic={true}>
                          https://
                          <Typography.Text strong={true}>
                            client11
                          </Typography.Text>
                          .sierrainteractivedev.com
                        </Typography.Text>{' '}
                        use{' '}
                        <Typography.Text strong={true}>
                          client11
                        </Typography.Text>
                      </li>
                    </>
                  )}
                </ol>
              </li>
            </ol>
          ) : (
            <Typography.Text>Unsupported CRM type</Typography.Text>
          )}
        </div>
        <div
          style={{ padding: 20, margin: '3px', borderLeft: '1px solid #ccc' }}
        >
          <Title level={2}>Create Client Account</Title>
          <Form autoComplete="off" form={form} onFinish={handleVerify}>
            <Form.Item label="CRM Type">
              <Typography.Text>{client?.crmType}</Typography.Text>
            </Form.Item>
            <Form.Item label="Friendly Name:">
              <Typography.Text>{client?.friendlyName}</Typography.Text>
            </Form.Item>
            {(client?.crmType === 'sierra' || client?.crmType === 'kvcore') && (
              <Form.Item
                label="Client Name"
                name="clientName"
                rules={[
                  { required: true, message: 'Client Name is required.' },
                ]}
              >
                <Input onChange={e => setClientNameValue(e.target.value)} />
              </Form.Item>
            )}
            <Form.Item
              label="Is Small Team"
              name="isSmallTeam"
              valuePropName="checked"
            >
              <Checkbox
                onChange={e => {
                  setIsSmallTeam(e.target.checked);
                  if (e.target.checked) {
                    form.setFieldsValue({
                      email: undefined,
                      accountPassword: undefined,
                      username: undefined,
                    });
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="API Key"
              name="apiKey"
              rules={[{ required: true, message: 'API Key is required.' }]}
            >
              <Input />
            </Form.Item>
            {!isSmallTeam && (
              <>
                {client?.crmType === 'kvcore' && (
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: !isSmallTeam, message: 'Email is required.' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}
                {client?.crmType === 'sierra' && (
                  <Form.Item
                    label="Account Username"
                    name="username"
                    rules={[
                      {
                        required: !isSmallTeam,
                        message: 'Account Username is required.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}
                <Form.Item
                  label="Account Password"
                  name="accountPassword"
                  rules={[
                    {
                      required: !isSmallTeam,
                      message: 'Account Password is required.',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
            )}
            {client?.crmType === 'sierra' && (
              <>
                <Form.Item
                  label="Site Name"
                  name="siteName"
                  rules={[
                    { required: true, message: 'Site Name is required.' },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Subdomain"
                  name="subdomain"
                  rules={[
                    { required: true, message: 'Subdomain is required.' },
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
            )}
            <Button htmlType="submit" loading={loadingVerify} type="primary">
              Verify Client
            </Button>
            {validationError && (
              <Alert message={validationError} showIcon type="error" />
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default VerifyClientPage;
