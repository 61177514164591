import { Button, Flex, List, Spin, Tag, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../UserContext';
import { Agent } from '../../types';

const { Text } = Typography;

interface ApiTask {
  id: string;
  title: string;
  description: string;
  details: string;
  due_date: string;
  is_completed: boolean;
  lead_source_id?: string;
  lead?: {
    first_name: string;
    last_name: string;
    phone?: string;
    email?: string;
  };
}

interface TasksProps {
  agent: Agent;
  apiUrl: string;
}

export const Tasks = ({ agent, apiUrl }: TasksProps): JSX.Element => {
  const user = useContext(UserContext);
  const [tasks, setTasks] = useState<ApiTask[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchTasks = async (agent: Agent) => {
    try {
      setLoading(true);
      if (!user?.accessToken) {
        console.error('No access token available');
        return;
      }

      const url = `${apiUrl}/api/agents/${agent.id}/tasks`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch tasks: ${response.status}`);
      }

      const jsonData = await response.json();
      setTasks(jsonData);
    } catch (err) {
      console.error('An error occurred while fetching tasks:', err);
      setTasks([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks(agent).then().catch(console.log);
  }, [agent]);

  const formatDate = (dateString: string) => {
    if (!dateString) {
      return 'No date set';
    }
    return new Date(dateString).toLocaleString('en', {
      timeZone: agent.timezone || 'UTC',
      dateStyle: 'medium',
      timeStyle: 'short',
    });
  };

  // Sort tasks by due date (closest first)
  const sortedTasks = tasks?.slice().sort((a, b) => {
    if (!a.due_date) {
      return 1;
    }
    if (!b.due_date) {
      return -1;
    }
    return new Date(a.due_date).getTime() - new Date(b.due_date).getTime();
  });

  return (
    <div>
      <Spin spinning={loading}>
        <Flex style={{ height: '70vh', overflowY: 'auto' }} vertical>
          <Button onClick={() => void fetchTasks(agent)}>Refresh</Button>

          {tasks && tasks.length === 0 && (
            <Text style={{ margin: '20px 0', textAlign: 'center' }}>
              No tasks found for this agent.
            </Text>
          )}

          <List
            dataSource={sortedTasks}
            itemLayout="vertical"
            renderItem={task => (
              <List.Item
                extra={
                  <div style={{ textAlign: 'right' }}>
                    {task.is_completed && <Tag color="green">Completed</Tag>}
                    {!task.is_completed &&
                      task.due_date &&
                      new Date(task.due_date) < new Date() && (
                        <Tag color="red">Overdue</Tag>
                      )}
                  </div>
                }
                style={{
                  background: '#f5f5f5',
                  padding: '16px',
                  borderRadius: '8px',
                  margin: '8px 0',
                }}
              >
                <List.Item.Meta
                  description={
                    <div>
                      <Text>Due: {formatDate(task.due_date)}</Text>
                      {task.lead && (
                        <div>
                          <Text>
                            For: {task.lead.first_name} {task.lead.last_name}
                          </Text>
                        </div>
                      )}
                    </div>
                  }
                  title={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Text strong>{task.title}</Text>
                    </div>
                  }
                />
                {task.details && (
                  <div style={{ marginTop: '10px' }}>
                    <Text type="secondary">Details:</Text>
                    <div
                      style={{
                        background: '#fff',
                        padding: '8px',
                        borderRadius: '4px',
                        marginTop: '4px',
                      }}
                    >
                      {task.details}
                    </div>
                  </div>
                )}
                <div
                  style={{ marginTop: '10px', fontSize: '12px', color: '#888' }}
                >
                  <div>Task ID: {task.id}</div>
                  {task.lead_source_id && (
                    <div>Lead ID: {task.lead_source_id}</div>
                  )}
                </div>
              </List.Item>
            )}
          />
        </Flex>
      </Spin>
    </div>
  );
};
