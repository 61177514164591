import {
  CheckCircleTwoTone,
  MinusOutlined,
  PlusOutlined,
  RedoOutlined,
  SaveOutlined,
} from '@ant-design/icons'; // Import Ant Design icons
import { Alert, Button, Checkbox, Input, Space, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OnboardingFlow } from '../../types';
import { UserContext } from '../../UserContext';

interface UpdateData {
  greeting_message?: string;
  phone_override?: string;
  is_isa?: boolean;
}

interface OnboardingStatus {
  id: string;
  source_id: string;
  agent_id: string;
  is_complete: boolean;
  stage: string;
  first_name: string;
  last_name: string;
  has_inbound_message: boolean;
  outbound_message_count?: number;
  call_time?: string;
}

interface CombinedOnboardingData extends OnboardingFlow {
  status?: OnboardingStatus;
}

export const OnboardingNewPage = ({ apiUrl }: { apiUrl: string }) => {
  const user = useContext(UserContext);
  const [onboardingData, setOnboardingData] = useState<
    Record<string, CombinedOnboardingData>
  >({});
  const [updatedData, setUpdatedData] = useState<Record<string, UpdateData>>(
    {},
  );
  const [loadingSendAll, setLoadingSendAll] = useState(false);
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [hideCompleted, setHideCompleted] = useState(false);
  const [showCompleteFlows, setShowCompleteFlows] = useState(false);
  const { clientId } = useParams();

  const fetchFlowData = async () => {
    try {
      setLoading(true);
      const flowResponse = await fetch(
        `${apiUrl}/api/onboarding/flow/${clientId}?get_complete=${showCompleteFlows}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.accessToken}`,
          },
        },
      );
      const flowData: Array<OnboardingFlow> = await flowResponse.json();
      return flowData.reduce((acc: Record<string, OnboardingFlow>, flow) => {
        acc[flow.id] = flow;
        return acc;
      }, {});
    } catch (err) {
      console.error('An error occurred while fetching flow data:', err);
      setError('Failed to fetch onboarding flow data');
      return {};
    } finally {
      setLoading(false);
    }
  };

  const fetchStatusData = async () => {
    try {
      const statusResponse = await fetch(
        `${apiUrl}/api/onboarding/status/${clientId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.accessToken}`,
          },
        },
      );
      const statusArray: Array<OnboardingStatus> = await statusResponse.json();

      // Create a dictionary of status data keyed by agent_id
      return statusArray.reduce(
        (acc: Record<string, OnboardingStatus>, status) => {
          acc[status.agent_id] = status;
          return acc;
        },
        {},
      );
    } catch (err) {
      console.error('An error occurred while fetching status data:', err);
      setError('Failed to fetch status data');
      return {};
    }
  };

  const updateCombinedData = (
    flows: Record<string, OnboardingFlow>,
    statuses: Record<string, OnboardingStatus>,
  ) => {
    const combinedData = Object.entries(flows).reduce(
      (acc: Record<string, CombinedOnboardingData>, [id, flow]) => {
        acc[id] = {
          ...flow,
          status: statuses[flow.agent_id],
        };
        return acc;
      },
      {},
    );
    setOnboardingData(combinedData);
  };

  useEffect(() => {
    // Initial load of both flow and status data
    const initializeData = async () => {
      const flows = await fetchFlowData();
      const statuses = await fetchStatusData();
      updateCombinedData(flows, statuses);
    };
    initializeData();
  }, [clientId, showCompleteFlows]);

  useEffect(() => {
    // Only refresh status data on interval
    const interval = setInterval(async () => {
      const statuses = await fetchStatusData();
      updateCombinedData(onboardingData, statuses);
    }, 60000); // Refresh every minute
    return () => clearInterval(interval);
  }, [clientId, onboardingData]);

  const handleUpdateData = (key: string, id: string, value: any) => {
    setUpdatedData(prev => ({ ...prev, [id]: { ...prev[id], [key]: value } }));
  };

  const savePhoneOverride = async (flow_id: string) => {
    // Implement save logic here, e.g., API call to save the phone override
    const override = updatedData[flow_id]?.phone_override;
    const flow = onboardingData[flow_id];
    if (!flow) {
      setError('Flow not found');
      return;
    }
    if (override == null || override === '') {
      setError('Phone override cannot be empty');
      return;
    }
    try {
      // Save logic here
      const response = await fetch(`${apiUrl}/api/agent/${flow.agent_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
        },
        body: JSON.stringify({ phone_override: override }),
      });
      if (!response.ok) {
        throw new Error(
          `Failed to save phone override: ${response.statusText}`,
        );
      }
      const jsonData = await response.json();
      const data = { ...onboardingData };
      data[flow.id].agent.phone_override = override;
      setOnboardingData(data);
      handleUpdateData('phone_override', flow.id, '');
    } catch (err) {
      setError(`${err}`);
      return;
    }
  };

  const saveGreetingMessage = async (flow_id: string) => {
    // Implement save logic here, e.g., API call to save the phone override
    const override = updatedData[flow_id]?.greeting_message;
    const flow = onboardingData[flow_id];
    if (!flow) {
      setError('Flow not found');
      return;
    }
    if (override == null || override === '') {
      setError('Greeting Message cannot be empty');
      return;
    }
    try {
      // Save logic here
      const response = await fetch(`${apiUrl}/api/onboarding/flow/${flow_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
        },
        body: JSON.stringify({ greeting_message: override }),
      });
      if (!response.ok) {
        throw new Error(
          `Failed to save greeting message: ${response.statusText}`,
        );
      }
      //   const jsonData = await response.json();
      const data = { ...onboardingData };
      data[flow.id].greeting_message = override;
      setOnboardingData(data);
      handleUpdateData('greeting_message', flow.id, '');
    } catch (err) {
      setError(`${err}`);
      return;
    }
  };

  const redoGreetingMessage = async (flow_id: string) => {
    const flow = onboardingData[flow_id];
    try {
      const response = await fetch(
        `${apiUrl}/api/onboarding/flow/${flow.agent_id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.accessToken}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error(`Failed to redo flow: ${response.statusText}`);
      }
      const jsonData = await response.json();
      const data = { ...onboardingData };
      data[flow.id] = { ...data[flow.id], ...jsonData };
      setOnboardingData(data);
    } catch (err) {
      setError(`${err}`);
      return;
    }
  };

  const sendGreetingMessage = async (flow_id: string) => {
    try {
      await _sendGreetingMessage(flow_id);
    } catch (err) {
      setError(`${err}`);
      return;
    }
  };

  const _sendGreetingMessage = async (flow_id: string) => {
    const flow = onboardingData[flow_id];
    const response = await fetch(
      `${apiUrl}/api/onboarding/send-greeting/${flow.agent_id}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
        },
      },
    );
    if (!response.ok) {
      throw new Error(
        `Failed to send greeting for ${flow.agent.first_name} ${flow.agent.last_name}: ${response.statusText}`,
      );
    }
    const data = { ...onboardingData };
    const jsonData = await response.json();
    data[flow.id].greeting_sent_at = jsonData.onboarding_flow.greeting_sent_at;
    setOnboardingData(data);
    const message =
      'I will call you in a couple minutes mins to introduce myself! Prefer another time? Let me know!';
    const responseSecond = await fetch(`${apiUrl}/api/calls/sms`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user?.accessToken}`,
      },
      body: JSON.stringify({
        agentId: flow.agent_id,
        message: message,
        meta: { type: 'onboarding' },
      }),
    });
    if (!responseSecond.ok) {
      throw new Error(
        `Failed to send call SMS to ${flow.agent.first_name} ${flow.agent.last_name}:: ${responseSecond.statusText}`,
      );
    }
  };

  const sendAll = async () => {
    try {
      const usableRows = getUsableRows();
      if (usableRows.length === 0) {
        setError('No rows with valid phone numbers to send messages to');
        return;
      }

      setLoadingSendAll(true);
      const limit = 10;
      const flowIds = usableRows.map(row => row.id);
      const exceptions = [];
      for (let i = 0; i < flowIds.length; i += limit) {
        const chunk = flowIds.slice(i, i + limit);
        const results = await Promise.allSettled(
          chunk.map(flow_id => sendGreetingMessage(flow_id)),
        );
        for (const result of results) {
          if (result.status === 'rejected') {
            exceptions.push(result.reason);
          }
        }
      }
      if (exceptions.length > 0) {
        setError(`Failed to send greeting messages: ${exceptions.join('\n')}`);
      }
    } catch (err) {
      console.error('Error sending all greetings:', err);
      setError('Failed to send all greetings');
    } finally {
      setLoadingSendAll(false);
    }
  };

  const callAll = async () => {
    try {
      const usableRows = getUsableRows();
      if (usableRows.length === 0) {
        setError('No rows with valid phone numbers to call');
        return;
      }

      setLoadingSendAll(true);
      const limit = 10;
      const flowIds = usableRows.map(row => row.id);
      const exceptions = [];
      for (let i = 0; i < flowIds.length; i += limit) {
        const chunk = flowIds.slice(i, i + limit);
        const results = await Promise.allSettled(
          chunk.map(flow_id => _callAgent(flow_id)),
        );
        for (const result of results) {
          if (result.status === 'rejected') {
            exceptions.push(result.reason);
          }
        }
      }
      if (exceptions.length > 0) {
        setError(`Failed to call: ${exceptions.join('\n')}`);
      }
    } catch (err) {
      console.error('Error calling all agents:', err);
      setError('Failed to call all agents');
    } finally {
      setLoadingSendAll(false);
    }
  };

  const _callAgent = async (flow_id: string) => {
    const flow = onboardingData[flow_id];
    const response = await fetch(
      `${apiUrl}/api/onboarding/call/${flow.agent_id}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
        },
      },
    );
    if (!response.ok) {
      throw new Error(
        `Failed to call ${flow.agent.first_name} ${flow.agent.last_name}: ${response.statusText}`,
      );
    }
  };

  const markFailed = async (flow_id: string) => {
    try {
      const flow = onboardingData[flow_id];
      const response = await fetch(
        `${apiUrl}/api/onboarding/flow/${flow_id}/mark-failed`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.accessToken}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error(
          `Failed to mark ${flow.agent.first_name} ${flow.agent.last_name} as failed: ${response.statusText}`,
        );
      }

      // Update local state
      const data = { ...onboardingData };
      data[flow_id].stage = 'failed';
      data[flow_id].is_complete = true;
      if (data[flow_id].status) {
        data[flow_id].status.stage = 'failed';
        data[flow_id].status.is_complete = true;
      }
      setOnboardingData(data);
    } catch (err) {
      setError(`${err}`);
    }
  };

  const callAgent = async (flow_id: string) => {
    try {
      await _callAgent(flow_id);
    } catch (err) {
      setError(`${err}`);
      return;
    }
  };

  const saveIsIsa = async (flow_id: string) => {
    const flow = onboardingData[flow_id];
    if (!flow) {
      setError('Flow not found');
      return;
    }
    const is_isa = !flow.agent.is_isa;
    try {
      const response = await fetch(`${apiUrl}/api/agent/${flow.agent_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
        },
        body: JSON.stringify({ is_isa: is_isa }),
      });
      if (!response.ok) {
        throw new Error(`Failed to save ISA status: ${response.statusText}`);
      }
      const data = { ...onboardingData };
      data[flow.id].agent.is_isa = is_isa;
      setOnboardingData(data);
    } catch (err) {
      setError(`${err}`);
      return;
    }
  };

  const isFlowDisabled = (record: CombinedOnboardingData) => {
    const stage = record.stage?.toLowerCase();
    return stage === 'complete' || stage === 'failed';
  };

  const isValidPhone = useCallback((phone: string) => {
    // Remove the + if it exists at the start
    const withoutPlus = phone.startsWith('+') ? phone.slice(1) : phone;
    const withoutOne = withoutPlus.startsWith('1')
      ? withoutPlus.slice(1)
      : withoutPlus;

    const digits = withoutOne.replace(/\D/g, '');
    return digits.length === 10;
  }, []);

  const getPhoneValidationStatus = (phone: string | null | undefined) => {
    if (!phone) {
      return undefined;
    }
    return isValidPhone(phone) ? undefined : 'error';
  };

  const duplicatePhones = useMemo(() => {
    const phoneMap = new Map<string, Array<string>>();
    Object.values(onboardingData).forEach(data => {
      const phone = updatedData[data.id]?.phone_override || data.agent.phone;
      if (!phone) {
        return;
      }
      const normalizedPhone = phone.replace(/\D/g, '');
      const existing = phoneMap.get(normalizedPhone) || [];
      phoneMap.set(normalizedPhone, [
        ...existing,
        `${data.agent.first_name} ${data.agent.last_name}`,
      ]);
    });
    return Array.from(phoneMap.entries())
      .filter(([_, agents]) => agents.length > 1)
      .map(([phone, agents]) => ({
        phone,
        agents,
      }));
  }, [onboardingData, updatedData]);

  const isDuplicatePhone = useCallback(
    (phone: string) => {
      return duplicatePhones.some(dup => {
        const normalizedInput = phone.replace(/\D/g, '');
        const normalizedDup = dup.phone.replace(/\D/g, '');
        return normalizedInput === normalizedDup;
      });
    },
    [duplicatePhones],
  );

  const getEffectivePhone = useCallback(
    (record: CombinedOnboardingData) => {
      const override =
        updatedData[record.id]?.phone_override || record.agent.phone_override;
      return override || record.agent.phone || '';
    },
    [updatedData],
  );

  const isPhoneUsable = useCallback(
    (record: CombinedOnboardingData) => {
      const phone = getEffectivePhone(record);
      if (!phone) {
        return false;
      }

      // If there's an override, only check the override's validity
      if (
        updatedData[record.id]?.phone_override ||
        record.agent.phone_override
      ) {
        return isValidPhone(phone) && !isDuplicatePhone(phone);
      }

      // Otherwise check the original phone
      return isValidPhone(phone) && !isDuplicatePhone(phone);
    },
    [getEffectivePhone, isValidPhone, isDuplicatePhone, updatedData],
  );

  const getActiveRows = useCallback(() => {
    return Object.values(onboardingData).filter(data => !isFlowDisabled(data));
  }, [onboardingData, isFlowDisabled]);

  const getUsableRows = useCallback(() => {
    return getActiveRows().filter(record => isPhoneUsable(record));
  }, [getActiveRows, isPhoneUsable]);

  const hasUsableRows = useMemo(() => {
    const usableRows = getUsableRows();
    return usableRows.length > 0;
  }, [getUsableRows]);

  const filteredData = useMemo(
    () =>
      Object.values(onboardingData).filter(data => {
        const nameMatch = `${data.agent.first_name} ${data.agent.last_name}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase());

        if (!nameMatch) {
          return false;
        }

        if (hideCompleted) {
          return !isFlowDisabled(data);
        }

        return true;
      }),
    [onboardingData, searchTerm, hideCompleted],
  );

  const columns: ColumnsType<CombinedOnboardingData> = [
    {
      title: 'Name',
      key: 'name',
      render: (_, record) => (
        <Space direction="vertical" size="small">
          <span>{`${record.agent.first_name} ${record.agent.last_name}`}</span>
          <Space size="small">
            <Button
              disabled={isFlowDisabled(record) || !isPhoneUsable(record)}
              onClick={() => void sendGreetingMessage(record.id)}
              size="small"
            >
              Resend
            </Button>
            <Button
              disabled={isFlowDisabled(record) || !isPhoneUsable(record)}
              onClick={() => void _callAgent(record.id)}
              size="small"
            >
              Call
            </Button>
            <Button
              disabled={isFlowDisabled(record)}
              onClick={() => void markFailed(record.id)}
              size="small"
              title="Mark agent as failed and complete"
            >
              Mark Failed
            </Button>
          </Space>
        </Space>
      ),
    },
    {
      title: 'ISA',
      key: 'is_isa',
      render: (_, record) => (
        <Space>
          <Checkbox
            checked={record.agent.is_isa}
            disabled={isFlowDisabled(record)}
            onChange={() => void saveIsIsa(record.id)}
          />
        </Space>
      ),
    },
    {
      title: 'Flow Status',
      key: 'stage',
      render: (_, record) => (
        <Space>
          <span>{record.status?.stage || 'N/A'}</span>
        </Space>
      ),
    },
    {
      title: 'Phone',
      key: 'phone',
      render: (_, record) => {
        const phone = record.agent.phone || '';
        const isDuplicate = phone && isDuplicatePhone(phone);
        const isInvalid = phone && !isValidPhone(phone);
        return (
          <span
            style={
              isDuplicate
                ? { color: '#ff4d4f' }
                : isInvalid
                  ? { color: '#ff4d4f', textDecoration: 'line-through' }
                  : undefined
            }
          >
            {phone}
          </span>
        );
      },
    },
    {
      title: 'Phone Override',
      key: 'phone_override',
      render: (_, record) => {
        const override =
          updatedData[record.id]?.phone_override ||
          record.agent.phone_override ||
          '';
        const isDuplicate = override && isDuplicatePhone(override);
        const isInvalid = override && !isValidPhone(override);

        return (
          <Space>
            <Input
              disabled={isFlowDisabled(record)}
              onChange={e =>
                handleUpdateData('phone_override', record.id, e.target.value)
              }
              placeholder="Phone Override"
              status={isDuplicate ? 'error' : isInvalid ? 'error' : undefined}
              style={{
                width: '150px',
              }}
              value={override}
            />
            <Button
              disabled={Boolean(
                !updatedData[record.id]?.phone_override ||
                  isFlowDisabled(record) ||
                  (override && !isValidPhone(override)),
              )}
              icon={<SaveOutlined />}
              onClick={() => void savePhoneOverride(record.id)}
            />
            {isInvalid && (
              <Tooltip title="Phone number must be 10 digits">
                <span style={{ color: '#ff4d4f' }}>Invalid</span>
              </Tooltip>
            )}
          </Space>
        );
      },
    },
    {
      title: 'Email',
      key: 'email',
      render: (_, record) => record.agent.email,
    },
    {
      title: 'Outbound Messages',
      key: 'outbound_messages',
      render: (_, record) => (
        <Space>
          <span>{record.status?.outbound_message_count || 0}</span>
        </Space>
      ),
    },
    {
      title: 'Agent Texted',
      key: 'agent_texted',
      render: (_, record) => (
        <Space>
          {record.status?.has_inbound_message && (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          )}
        </Space>
      ),
    },
    {
      title: 'Scheduled Call',
      key: 'scheduledCall',
      render: (_, record) => (
        <span>{record.status?.call_time || 'Not scheduled'}</span>
      ),
    },
  ];

  const publishBios = async () => {
    try {
      setLoadingPublish(true);
      const response = await fetch(`${apiUrl}/api/onboarding/publish`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
        },
        body: JSON.stringify({
          client_id: clientId,
          agent_ids: Object.values(onboardingData)
            .filter(data => !isFlowDisabled(data))
            .map(data => data.agent.id),
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to publish bios');
      }

      setError('Successfully published bios');
    } catch (err) {
      console.error('Error publishing bios:', err);
      setError('Failed to publish bios');
    } finally {
      setLoadingPublish(false);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>Onboarding for {clientId}</h2>
      {error && (
        <Alert
          closable
          message={error}
          onClose={() => setError(undefined)}
          type="error"
        />
      )}
      {duplicatePhones.length > 0 && (
        <Alert
          banner
          description={
            <div>
              {duplicatePhones.map(({ phone, agents }) => (
                <div key={phone}>
                  Phone: {phone} is used by: {agents.join(', ')}
                </div>
              ))}
            </div>
          }
          message="Duplicate Phone Numbers Detected"
          style={{ marginBottom: '16px' }}
          type="warning"
        />
      )}
      <Space style={{ marginBottom: 16 }}>
        <Input
          onChange={e => setSearchTerm(e.target.value)}
          placeholder="Search by name"
          style={{ width: '300px' }}
        />
        <Checkbox
          checked={hideCompleted}
          onChange={e => setHideCompleted(e.target.checked)}
        >
          Show Agents to Onboard
        </Checkbox>
        <Checkbox
          checked={showCompleteFlows}
          onChange={e => setShowCompleteFlows(e.target.checked)}
        >
          Include Completed Onboardings
        </Checkbox>
        <Button disabled={!hasUsableRows} onClick={() => void sendAll()}>
          Send All Greetings
        </Button>
        <Button disabled={!hasUsableRows} onClick={() => void callAll()}>
          Call All
        </Button>
        <Button loading={loadingPublish} onClick={() => void publishBios()}>
          Publish Bios
        </Button>
      </Space>

      <Table
        columns={columns}
        dataSource={filteredData}
        expandable={{
          expandedRowRender: record => (
            <div style={{ padding: '20px', width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '20px',
                }}
              >
                {/* Greeting Message Section - Left Side */}
                <div style={{ flex: 1 }}>
                  <h4>Greeting Message</h4>
                  <Space>
                    <Input.TextArea
                      disabled={isFlowDisabled(record)}
                      onChange={e =>
                        handleUpdateData(
                          'greeting_message',
                          record.id,
                          e.target.value,
                        )
                      }
                      rows={5}
                      style={{ width: '100%', minWidth: '300px' }}
                      value={
                        updatedData[record.id]?.greeting_message ||
                        record.greeting_message ||
                        ''
                      }
                    />
                    <Space direction="vertical">
                      <Tooltip title="Regenerate and update the greeting message">
                        <Button
                          disabled={isFlowDisabled(record)}
                          icon={<RedoOutlined />}
                          onClick={() => void redoGreetingMessage(record.id)}
                        />
                      </Tooltip>
                      <Button
                        disabled={
                          !updatedData[record.id]?.greeting_message ||
                          isFlowDisabled(record)
                        }
                        icon={<SaveOutlined />}
                        onClick={() => void saveGreetingMessage(record.id)}
                      />
                    </Space>
                  </Space>
                </div>

                {/* Lead Bio Section - Right Side */}
                {record.str_bio && (
                  <div style={{ flex: 1 }}>
                    <h4>Lead Bio</h4>
                    <div style={{ maxHeight: 200, overflow: 'auto' }}>
                      <a href={record.crm_link}>CRM Link</a>
                      <br />
                      <pre
                        style={{
                          textAlign: 'left',
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'break-word',
                        }}
                      >
                        {record.str_bio}
                      </pre>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ),
          expandIcon: ({ expanded, onExpand, record }) =>
            record.greeting_message || record.str_bio ? (
              <Button
                icon={expanded ? <MinusOutlined /> : <PlusOutlined />}
                onClick={e => onExpand(record, e)}
                type="text"
              />
            ) : null,
          rowExpandable: record =>
            !!(record.greeting_message || record.str_bio),
        }}
        loading={loading}
        pagination={false}
        rowKey="id"
        scroll={{ x: 1500 }}
      />
    </div>
  );
};
