import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  notification,
  Space,
  Table,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Agent, ClientFeatures } from '../../types';
import { AgentOverrides } from './agent-overrides';

interface AgentFormData {
  agents: Record<string, Agent>;
}

interface AgentSectionProps {
  apiUrl: string;
  clientConfigId: string;
  accessToken: string;
  setError: (error: string | undefined) => void;
  clientFeatures: ClientFeatures;
  clientId: number;
}

interface MatchedAgent {
  id: string;
  first_name: string;
  last_name: string;
}

interface MissingAgent {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
}

interface MatchedAgentsResponse {
  agents: MatchedAgent[];
  missing: MissingAgent[];
}

export function AgentSection({
  apiUrl,
  clientConfigId,
  accessToken,
  setError,
  clientFeatures,
  clientId,
}: AgentSectionProps) {
  const navigate = useNavigate();
  const [form] = Form.useForm<AgentFormData>();
  const [loading, setLoading] = useState(false);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [isTouched, setIsTouched] = useState(false);
  const [selectedAgentIds, setSelectedAgentIds] = useState<string[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<Agent>();
  const [isMatchModalVisible, setIsMatchModalVisible] = useState(false);
  const [rawAgentText, setRawAgentText] = useState('');
  const [matchLoading, setMatchLoading] = useState(false);

  const matchAgentsFromText = async () => {
    setMatchLoading(true);
    setError(undefined);

    try {
      const response = await fetch(`${apiUrl}/api/onboarding/match-agents`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          client_id: clientId,
          text: rawAgentText,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to match agents');
      }

      const data: MatchedAgentsResponse = await response.json();

      // Select all matched agents
      if (data.agents && data.agents.length > 0) {
        const matchedIds = data.agents.map(agent => agent.id);
        setSelectedAgentIds(prevSelected => {
          // Combine previously selected with newly matched, removing duplicates
          const uniqueIds = Array.from(
            new Set([...prevSelected, ...matchedIds]),
          );
          return uniqueIds;
        });
      }

      // Show notification for missing agents
      if (data.missing && data.missing.length > 0) {
        const missingNames = data.missing
          .map(
            agent =>
              `${agent.first_name} ${agent.last_name}${agent.email ? ` (${agent.email})` : ''}`,
          )
          .join(', ');

        notification.info({
          message: 'Some agents were not found',
          description: (
            <div>
              <p>The following agents could not be matched in the system:</p>
              <p>{missingNames}</p>
            </div>
          ),
          duration: 0,
          icon: <InfoCircleOutlined style={{ color: '#1890ff' }} />,
        });
      }

      setIsMatchModalVisible(false);
      setRawAgentText('');
    } catch (err) {
      setError(`${err}`);
    } finally {
      setMatchLoading(false);
    }
  };

  const fetchAgents = async () => {
    setLoading(true);
    setError(undefined);
    try {
      const response = await fetch(`${apiUrl}/api/agents/${clientConfigId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(`Failed to fetch agents: ${data.message}`);
      }
      setAgents(data);
      const agentsRecord = data.reduce(
        (acc: Record<string, Agent>, agent: Agent) => {
          acc[agent.id] = agent;
          return acc;
        },
        {},
      );
      form.setFieldsValue({ agents: agentsRecord });
    } catch (err) {
      setError(`${err}`);
    } finally {
      setLoading(false);
    }
  };

  const handleFinish = async (values: AgentFormData) => {
    setLoading(true);
    setError(undefined);
    try {
      const changedAgents = Object.keys(values.agents)
        .filter(agentId => {
          const agent = values.agents[agentId];
          const originalAgent = agents.find(a => a.id === agentId);
          return (
            originalAgent &&
            (agent.is_hw_enabled !== originalAgent.is_hw_enabled ||
              agent.phone_override !== originalAgent.phone_override ||
              agent.is_isa !== originalAgent.is_isa ||
              agent.hw_internal_notes !== originalAgent.hw_internal_notes)
          );
        })
        .map(agentId => {
          const agent = values.agents[agentId];
          return {
            id: agentId,
            is_hw_enabled: agent.is_hw_enabled,
            phone_override: agent.phone_override,
            is_isa: agent.is_isa,
            hw_internal_notes: agent.hw_internal_notes,
          };
        });

      if (changedAgents.length === 0) {
        setLoading(false);
        setIsTouched(false);
        return;
      }

      // Check if any agent is being enabled for House Whisper and has internal notes
      // Find all agents being enabled with notes
      const agentsWithNotes = changedAgents.filter(
        agent =>
          agent.is_hw_enabled &&
          agents.find(a => a.id === agent.id)?.hw_internal_notes,
      );

      if (agentsWithNotes.length > 0) {
        // Create a formatted list of agents with their notes
        const agentsList = agentsWithNotes
          .map(agentWithNote => {
            const agent = agents.find(a => a.id === agentWithNote.id);
            return `• ${agent?.first_name} ${agent?.last_name}: "${agent?.hw_internal_notes}"`;
          })
          .join('<br>'); // Use <br> tags for line breaks in the modal

        // Show confirmation modal with all agents that have notes
        const confirmEnable = await new Promise<boolean>(resolve => {
          Modal.confirm({
            title: 'Enabling House Whisper with Notes',
            content: (
              <div
                dangerouslySetInnerHTML={{
                  __html: `Are you sure you want to enable House Whisper for the following agents with notes?<br>${agentsList}`,
                }}
              />
            ),
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
            width: 500,
          });
        });

        if (!confirmEnable) {
          setLoading(false);
          return;
        }
      }

      const response = await fetch(`${apiUrl}/api/agents/${clientConfigId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(changedAgents),
      });

      if (!response.ok) {
        throw new Error('Failed to update agent settings');
      }

      await fetchAgents();
      setIsTouched(false);
    } catch (error) {
      console.error('Error updating agent settings:', error);
      setError(`${error}`);
    } finally {
      setLoading(false);
    }
  };

  const createOnboardingFlow = async () => {
    setLoading(true);
    setError(undefined);
    try {
      // Check if any selected agent has internal notes
      const agentsWithNotes = selectedAgentIds
        .filter(agentId => {
          const agent = agents.find(a => a.id === agentId);
          return agent?.hw_internal_notes;
        })
        .map(agentId => agents.find(a => a.id === agentId))
        .filter(Boolean) as Agent[];

      if (agentsWithNotes.length > 0) {
        // Create a formatted list of agents with their notes
        const agentsList = agentsWithNotes
          .map(agent => {
            return `• ${agent.first_name} ${agent.last_name}: "${agent.hw_internal_notes}"`;
          })
          .join('<br>');

        // Show confirmation modal with all agents that have notes
        const confirmContinue = await new Promise<boolean>(resolve => {
          Modal.confirm({
            title: 'Creating Onboarding with Agents That Have Notes',
            content: (
              <div
                dangerouslySetInnerHTML={{
                  __html: `Are you sure you want to create onboarding for the following agents with notes?<br>${agentsList}`,
                }}
              />
            ),
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
            width: 500,
          });
        });

        if (!confirmContinue) {
          setLoading(false);
          return;
        }
      }

      const response = await fetch(`${apiUrl}/api/onboarding/flow`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          client_config_id: clientConfigId,
          agent_ids: selectedAgentIds,
        }),
      });
      if (!response.ok) {
        throw new Error(await response.text());
      }

      navigate(`/onboardingNew/${clientId}`);
    } catch (error) {
      console.error('Error updating agent settings:', error);
      setError(`${error}`);
    } finally {
      setLoading(false);
    }
  };

  const renderTitle = (title: string, tooltip: string) => (
    <Space>
      {title}
      <Typography.Text style={{ fontSize: '14px' }} type="secondary">
        <QuestionCircleOutlined style={{ cursor: 'help' }} title={tooltip} />
      </Typography.Text>
    </Space>
  );

  const rowSelection = {
    selectedRowKeys: selectedAgentIds,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedAgentIds(selectedRowKeys as string[]);
    },
  };

  const columns: ColumnsType<Agent> = [
    {
      title: 'Name',
      key: 'name',
      width: '180px',
      render: (_: unknown, record: Agent) => (
        <Typography.Link onClick={() => setSelectedAgent(record)}>
          <Typography.Text>
            {record.first_name} {record.last_name}
          </Typography.Text>
        </Typography.Link>
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: '130px',
      render: (_: string, record: Agent) => (
        <Typography.Text>{record.phone || '-'}</Typography.Text>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '200px',
      render: (_: string, record: Agent) => (
        <Typography.Text>{record.email || '-'}</Typography.Text>
      ),
    },
    {
      title: renderTitle(
        'House Whisper Enabled',
        'Enable House Whisper features for this agent',
      ),
      dataIndex: 'is_hw_enabled',
      key: 'is_hw_enabled',
      width: '130px',
      align: 'center',
      render: (_: boolean, record: Agent) => (
        <Form.Item
          name={['agents', record.id, 'is_hw_enabled']}
          style={{ margin: 0, textAlign: 'center' }}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      ),
    },
    {
      title: renderTitle(
        'Phone Override',
        'Override the phone number for this agent',
      ),
      dataIndex: 'phone_override',
      key: 'phone_override',
      width: '180px',
      render: (_: string, record: Agent) => (
        <Form.Item
          name={['agents', record.id, 'phone_override']}
          style={{ margin: 0 }}
        >
          <Input placeholder="Enter phone number" />
        </Form.Item>
      ),
    },
    {
      title: renderTitle('ISA', 'Inside Sales Agent'),
      dataIndex: 'is_isa',
      key: 'is_isa',
      width: '100px',
      align: 'center',
      render: (_: boolean, record: Agent) => (
        <Form.Item
          name={['agents', record.id, 'is_isa']}
          style={{ margin: 0, textAlign: 'center' }}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      ),
    },
    {
      title: renderTitle(
        'SMS/Call',
        'Go to agent SMS/Call Page for this agent',
      ),
      key: 'sms_link',
      width: '150px',
      render: (_: unknown, record: Agent) => (
        <Typography.Link
          href={`/agentSms/${clientId}?agentId=${record.id}`}
          target="_blank"
        >
          SMS
        </Typography.Link>
      ),
    },
    {
      title: renderTitle(
        'Internal Notes',
        'House Whisper internal notes for this agent',
      ),
      dataIndex: 'hw_internal_notes',
      key: 'hw_internal_notes',
      width: '250px',
      render: (_: string, record: Agent) => (
        <Form.Item
          name={['agents', record.id, 'hw_internal_notes']}
          style={{ margin: 0 }}
        >
          <Input.TextArea
            placeholder="Add notes, e.g., told us to stop texting"
            rows={2}
          />
        </Form.Item>
      ),
    },
  ];
  useEffect(() => {
    void fetchAgents();
  }, []);
  return (
    <div
      style={{ textAlign: 'left', backgroundColor: 'white', padding: '20px' }}
    >
      <Typography.Title level={2}>Agent Configuration</Typography.Title>
      <Typography.Paragraph>
        Configure House Whisper settings for each agent. Enable or disable House
        Whisper features and set phone number overrides as needed.
      </Typography.Paragraph>
      <Form
        disabled={loading}
        form={form}
        // initialValues={{ agents }}
        onFinish={handleFinish}
        onValuesChange={() => setIsTouched(true)}
      >
        <Table
          columns={columns}
          dataSource={agents}
          pagination={false}
          rowKey="id"
          rowSelection={rowSelection}
        />
        <Space style={{ marginTop: '20px' }}>
          <Button disabled={!isTouched} htmlType="submit" type="primary">
            Save
          </Button>
          <Button
            disabled={selectedAgentIds.length === 0}
            loading={loading}
            onClick={createOnboardingFlow}
            type="primary"
          >
            Create Onboarding
          </Button>
          <Button onClick={() => setIsMatchModalVisible(true)} type="default">
            Match Agents
          </Button>
        </Space>
      </Form>

      {/* Match Agents Modal */}
      <Modal
        confirmLoading={matchLoading}
        onCancel={() => setIsMatchModalVisible(false)}
        onOk={matchAgentsFromText}
        title="Match Agents from Text"
        visible={isMatchModalVisible}
        width={600}
      >
        <Typography.Paragraph>
          Paste a list of agent names, emails, or other identifying information
          below. The system will attempt to match them with agents in the
          database.
        </Typography.Paragraph>
        <Input.TextArea
          onChange={e => setRawAgentText(e.target.value)}
          placeholder="Example:
John Smith jsmith@example.com
Mary Johnson
Tom Wilson (tomw@domain.com)
..."
          rows={10}
          value={rawAgentText}
        />
      </Modal>

      {selectedAgent && (
        <AgentOverrides
          accessToken={accessToken}
          agent={selectedAgent}
          apiUrl={apiUrl}
          clientFeatures={clientFeatures}
          clientId={clientConfigId}
          onClose={() => setSelectedAgent(undefined)}
          visible={true}
        />
      )}
    </div>
  );
}
