import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../UserContext';
import { Table, Tooltip } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

interface OnboardingStatus {
  id: string;
  source_id: string;
  is_complete: boolean;
  stage: string;
  first_name: string;
  last_name: string;
  has_inbound_message: boolean;
}

export const OnboardingStatusPage = ({ apiUrl }: { apiUrl: string }) => {
  const user = useContext(UserContext);
  const [statusData, setStatusData] = useState<OnboardingStatus[]>([]);
  const [loading, setLoading] = useState(false);
  const { clientId } = useParams();

  const fetchStatusData = async () => {
    try {
      if (!user?.accessToken) {
        console.error('No access token available');
        return;
      }

      setLoading(true);
      const response = await fetch(
        `${apiUrl}/api/onboarding/status/${clientId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.accessToken}`,
          },
        },
      );
      const jsonData: Record<string, OnboardingStatus> = await response.json();
      setStatusData(Object.values(jsonData));
    } catch (err) {
      console.error(
        'An error occurred while fetching the onboarding status:',
        err,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStatusData();
    const interval = setInterval(fetchStatusData, 15000); // Refresh every 15 seconds
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [clientId]);

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a: OnboardingStatus, b: OnboardingStatus) =>
        a.first_name.localeCompare(b.first_name),
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a: OnboardingStatus, b: OnboardingStatus) =>
        a.last_name.localeCompare(b.last_name),
      defaultSortOrder: 'ascend' as const, // Set default sort order
    },
    {
      title: 'Stage',
      dataIndex: 'stage',
      key: 'stage',
    },
    {
      title: 'Is Complete',
      dataIndex: 'is_complete',
      key: 'is_complete',
      render: (text: boolean) => (
        <Tooltip title={text ? 'Yes' : 'No'}>
          <span style={{ position: 'absolute', opacity: 0 }}>
            {text ? 'Yes' : 'No'}
          </span>
          {text ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#ff4d4f" />
          )}
        </Tooltip>
      ),
    },
    {
      title: 'Outbound Message Count',
      dataIndex: 'outbound_message_count',
      key: 'outbound_message_count',
    },
    {
      title: 'Has Inbound Message',
      dataIndex: 'has_inbound_message',
      key: 'has_inbound_message',
      render: (text: boolean) => (
        <Tooltip title={text ? 'Yes' : 'No'}>
          <span style={{ position: 'absolute', opacity: 0 }}>
            {text ? 'Yes' : 'No'}
          </span>
          {text ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#ff4d4f" />
          )}
        </Tooltip>
      ),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Call Time',
      dataIndex: 'call_time',
      key: 'call_time',
    },
  ];

  return (
    <div>
      <h2>Onboarding Status</h2>
      <Table
        columns={columns}
        dataSource={statusData}
        loading={loading}
        pagination={false}
        rowKey={record => record.id}
      />
    </div>
  );
};

export default OnboardingStatusPage;
