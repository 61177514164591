import NavMenu from '../NavMenu';
import { SignInButton } from '../SignInButton';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { Outlet } from 'react-router-dom';

export function AuthLayout() {
  return (
    <>
      <UnauthenticatedTemplate>
        <SignInButton loginType="popup" />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <NavMenu />
        <Outlet />
      </AuthenticatedTemplate>
    </>
  );
}
