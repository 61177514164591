import { Button, Input, Typography } from 'antd';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../UserContext';
import { useParams } from 'react-router-dom';
import { AppointmentsResponse, Config, Recording, Task } from '../../types';
const { TextArea } = Input;
const { Title, Paragraph } = Typography;

import './styles.css';

import { DatePicker, TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { fetchCrmLink } from '../../utils/client-utils';
import AppointmentTypesDropdown from './appointments-types';

dayjs.extend(utc);
dayjs.extend(timezone);

interface TaskComponentProps {
  handleTaskChange: (value: string, taskId: string, fieldName: string) => void;
  task: Task;
  formattedDueDate: string;
  date_key: string;
  config: Config;
}

const TaskComponent: React.FC<TaskComponentProps> = ({
  handleTaskChange,
  task,
  formattedDueDate,
  date_key,
  config,
}) => {
  // Log the original formattedDueDate
  console.log('Original formattedDueDate:', formattedDueDate);
  console.log('Timezone:', config.timezone);

  // Convert the formattedDueDate to the specified timezone
  const initialDate = dayjs.utc(formattedDueDate).tz(config.timezone);
  const initialTime = dayjs.utc(formattedDueDate).tz(config.timezone);

  // Log the converted dates
  console.log('Converted initialDate:', initialDate.format());
  console.log('Converted initialTime:', initialTime.format());

  const [date, setDate] = useState<Dayjs>(initialDate);
  const [time, setTime] = useState<Dayjs>(initialTime);

  const handleDateChange = (dateValue: Dayjs | null) => {
    if (dateValue) {
      setDate(dateValue);
      const newDueDate = dateValue
        // .clone()
        .hour(time.hour())
        .minute(time.minute())
        .utc();
      handleTaskChange(newDueDate.format(), task.id, date_key);
    }
  };

  const handleTimeChange = (timeValue: Dayjs | null) => {
    if (timeValue) {
      setTime(timeValue);
      const newDueDate = date
        // .clone()
        .hour(timeValue.hour())
        .minute(timeValue.minute())
        .utc();
      handleTaskChange(newDueDate.format(), task.id, date_key);
    }
  };

  return (
    <div>
      <Title level={5}>
        {date_key.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}
      </Title>
      <DatePicker onChange={handleDateChange} value={date} />
      <TimePicker
        disabled={false}
        format="HH:mm"
        onChange={handleTimeChange}
        value={time}
      />
    </div>
  );
};

export const AppointmentReviewPage = ({
  apiUrl,
}: {
  apiUrl: string;
}): JSX.Element => {
  const user = useContext(UserContext);
  const [recording, setRecording] = useState<Recording>();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [sms, setSms] = useState<string>('');
  const [error, setError] = useState('');
  const [appointmentTypeError, setAppointmentTypeError] =
    useState<boolean>(false); // New state for error tracking
  const [config, setConfig] = useState<Config>();

  const handleErrorChange = (hasError: boolean) => {
    setAppointmentTypeError(hasError);
  };

  const { recordingID, clientId } = useParams<{
    recordingID: string;
    clientId: string;
  }>();

  useEffect(() => {
    const fetchRecording = async () => {
      try {
        console.log('fetchRecording:', recordingID);
        if (!user?.accessToken) {
          console.error('No access token available');
          return;
        }

        const url = `${apiUrl}/api/recordings/${clientId}/${recordingID}`;
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        });
        const jsonData = await response.json();

        if (jsonData && jsonData.recording) {
          // Replace all occurrences of '\n' with '<br/>'
          jsonData.recording = jsonData.recording.replace(/\n/g, '<br/>');
        }

        setRecording(jsonData);
      } catch (err) {
        console.error('An error occurred while fetching the lead:', err);
        setRecording(undefined);
        setError('Failed to load recording. Please try again later.');
      }
    };
    fetchRecording().then().catch(console.log);
  }, [apiUrl, clientId, recordingID]);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        if (!user?.accessToken) {
          console.error('No access token available');
          return;
        }

        const url = `${apiUrl}/api/configs/${clientId}`;
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        });
        const jsonData = await response.json();
        setConfig(jsonData);
      } catch (err) {
        console.error('An error occurred while fetching the config:', err);
        setConfig(undefined);
        setError('Failed to load config. Please try again later.');
      }
    };
    fetchConfig().then().catch(console.log);
  }, [recording]);

  useEffect(() => {
    if (recording && recording.user_id) {
      fetchTasks().catch(error =>
        console.error('Failed to execute loadTasks:', error),
      );
    }
  }, [recording]);

  useEffect(() => {
    const fetchSms = async () => {
      if (tasks.length === 0) {
        return;
      }
      try {
        const url = `${apiUrl}/api/appointments/create-sms/${tasks[0].id}`;
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`Failed to fetch sms: ${await response.text()}`);
        }
        const jsonData = await response.json();

        setSms(jsonData.sms);
      } catch (err) {
        console.error('An error occurred while fetching the lead:', err);
        setSms('');
        setError('Failed to load tasks. Please try again later.');
      }
    };
    fetchSms().catch(console.log);
  }, [tasks]);

  const updateTaskField = (taskId: string, field: string, value: string) => {
    console.log(`Updating task ${taskId} field ${field} with value ${value}`);
    const updatedTasks = tasks.map(task =>
      task.id === taskId ? { ...task, [field]: value, modified: true } : task,
    );

    console.log('Updated tasks:', updatedTasks);
    setTasks(updatedTasks);
  };

  const handleTaskChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    taskId: string,
    field: string,
  ): void => {
    updateTaskField(taskId, field, e.target.value);
  };

  const handleTaskDateChange = (
    value: string,
    taskId: string,
    field: string,
  ): void => {
    updateTaskField(taskId, field, value);
  };
  const [crmLink, setCrmLink] = useState('');
  // appointments are smartTasks
  const updateTask = async (taskId: string): Promise<void> => {
    const taskToUpdate: Task | undefined = tasks.find(
      task => task.id === taskId,
    );
    if (!taskToUpdate) {
      return;
    }

    try {
      if (!user?.accessToken) {
        console.error('No access token available');
        return;
      }

      const response = await fetch(`${apiUrl}/api/tasks/${taskId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify(taskToUpdate),
      });
      if (!response.ok) {
        throw new Error('Failed to update task');
      }

      console.log('Task updated successfully:', taskId);
      await fetchTasks();
    } catch (error) {
      console.error('Failed to update task:', taskId, error);
      setError('Failed to update task.');
    }
  };

  const handleAppointmentTypeModified = (
    taskId: string,
    appointmentTypeName: string,
    appointmentTypeId: string,
  ) => {
    const updatedTasks = tasks.map(task =>
      task.id === taskId
        ? {
            ...task,
            raw_task: {
              ...task.raw_task,
              appointment_type: appointmentTypeName,
              appointment_type_id:
                appointmentTypeId !== '-1' ? appointmentTypeId : null,
            },
            modified: true,
          }
        : task,
    );
    setTasks(updatedTasks);
  };

  const fetchTasks = async (): Promise<void> => {
    try {
      // appointments are smart tasks
      console.log('fetchTasks:', clientId, recording?.user_id);
      const response = await fetch(
        `${apiUrl}/api/appointments/agent/${clientId}/${recording?.user_id}?show_all=1`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      const data = await response.json();
      const TasksResponse = { data: data as AppointmentsResponse };

      console.log('fetchedTasks -> TasksResponse:', TasksResponse);
      const fetchedTasks = TasksResponse.data.appointments
        .filter(task => task.task_type === 'call_appointment')
        .filter(task => task.source_id === recording?.id)
        .sort((a, b) => a.created_at.localeCompare(b.created_at));

      const updatedTasks = fetchedTasks.map(task => ({
        ...task,
        modified: false,
      }));
      setTasks(updatedTasks);
      console.log('fetchedTasks:', updatedTasks);

      const crmLink = await fetchCrmLink(
        apiUrl,
        recording?.client_id.toString(),
        recording?.lead?.source_id,
        user?.accessToken,
      );
      if (crmLink) {
        setCrmLink(crmLink);
      }
    } catch (error) {
      console.error('Failed to fetch tasks:', error);
      setError('Failed to execute task loading. Please try again later.');
    }
  };

  const postAppointment = async (taskId: string): Promise<void> => {
    const taskToPost: Task | undefined = tasks.find(task => task.id === taskId);
    if (!taskToPost) {
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/api/appointments/post_appointment_to_crm`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ task: taskToPost, sms: sms }),
        },
      );
      if (!response.ok) {
        throw new Error('Failed to post appointment to crm');
      }
      await fetchTasks();
    } catch (error) {
      console.error('Failed to post appointment:', taskId, error);
      setError('Failed to post appointment.');
    }
  };

  return (
    <div>
      {error && <Paragraph>Error: {error}</Paragraph>}
      <Title level={1}>Appointment Review Page</Title>
      <div>
        <Title level={2}>Recording</Title>
        <Paragraph>
          <a href={crmLink} rel="noopener noreferrer" target="_blank">
            CRM link: {recording?.lead?.source_id}
          </a>
        </Paragraph>
        <div
          dangerouslySetInnerHTML={{ __html: recording?.recording || '' }}
          style={{ textAlign: 'left' }}
        ></div>
        <br />
        <Paragraph>{recording?.action_prompt_result?.summary}</Paragraph>
      </div>
      <div>
        <Title level={2}>Appointments</Title>
        {tasks.map(task => {
          return (
            <div className="task-container" key={task.id}>
              <Input
                onChange={e => handleTaskChange(e, task.id, 'title')}
                type="text"
                value={task.title}
              />
              <TextArea
                onChange={e => handleTaskChange(e, task.id, 'description')}
                value={task.description}
              />
              {config && (
                <>
                  <TaskComponent
                    config={config}
                    date_key="due_date"
                    formattedDueDate={task.due_date || ''}
                    handleTaskChange={handleTaskDateChange}
                    task={task}
                  />
                  <TaskComponent
                    config={config}
                    date_key="expired_at"
                    formattedDueDate={task.expired_at || ''}
                    handleTaskChange={handleTaskDateChange}
                    task={task}
                  />
                </>
              )}
              <AppointmentTypesDropdown
                apiUrl={apiUrl}
                clientId={recording?.lead?.client_id}
                onAppointmentTypeModified={handleAppointmentTypeModified}
                onErrorChange={handleErrorChange}
                task={task}
              />
              <Title level={5}>SMS</Title>
              <TextArea onChange={e => setSms(e.target.value)} value={sms} />

              <Button onClick={() => void updateTask(task.id)} type="primary">
                Update
              </Button>

              <Button
                disabled={
                  task.crm_task_id != null ||
                  task.modified ||
                  appointmentTypeError
                }
                onClick={() => void postAppointment(task.id)}
                type="primary"
              >
                Post to CRM
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
