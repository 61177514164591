import {
  Button,
  Input,
  message,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CreateClient,
  CreateSingleAgent,
} from '../../components/client/create-client';
import { UserContext } from '../../UserContext';

const { Title } = Typography;
const { Option } = Select;

interface Client {
  id: string;
  friendlyName: string;
  clientState: string;
  isSingleAgent: boolean;
}

const ClientsPage: React.FC<{ apiUrl: string }> = ({ apiUrl }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [clients, setClients] = useState<Client[]>([]);
  const [showSingleAgent, setShowSingleAgent] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [selectedClientState, setSelectedClientState] = useState<string>('all');
  const user = useContext(UserContext);

  const clientStates = useMemo(() => {
    const states = Array.from(
      new Set(clients.map(client => client.clientState)),
    );
    return ['all', ...states.sort()];
  }, [clients]);

  const filteredClients = useMemo(
    () =>
      clients
        .filter(client =>
          searchText
            ? client.friendlyName
                .toLowerCase()
                .includes(searchText.toLowerCase())
            : true,
        )
        .filter(client => (showSingleAgent ? true : !client.isSingleAgent))
        .filter(client =>
          selectedClientState && selectedClientState !== 'all'
            ? client.clientState === selectedClientState
            : true,
        ),
    [clients, showSingleAgent, searchText, selectedClientState],
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSingleAgentModalOpen, setIsSingleAgentModalOpen] = useState(false);

  const showNewClientModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        if (user == null) {
          return;
        }
        const response = await fetch(`${apiUrl}/api/clients/`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.accessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch clients');
        }
        const data = await response.json();

        setClients(data);
      } catch (error) {
        setClients([]);
        await message.error('Failed to load clients. Please try again later.');
        console.error('Error fetching clients:', error);
      } finally {
        setLoading(false);
      }
    };

    void fetchClients();
  }, [apiUrl, user?.accessToken]);

  const navigate = useNavigate();

  const handleRowClick = async (record: Client) => {
    if (record.clientState === 'created') {
      if (record.isSingleAgent) {
        navigate(`/client/${record.id}/verify-single-agent`);
      } else {
        navigate(`/client/${record.id}/verify`);
      }
    } else if (record.clientState === 'verification') {
      navigate(`/client/${record.id}/pending-verification`);
    } else if (record.clientState === 'backfill') {
      navigate(`/client/${record.id}/backfill-status`);
    } else if (record.clientState === 'backfill-failed') {
      await message.error('Backfill failed, reach out to engineering');
    } else {
      navigate(`/client/${record.id}/settings`);
    }
  };

  return (
    <div>
      <Title>Client Configuration</Title>
      <div style={{ marginBottom: 16, textAlign: 'left' }}>
        <Space align="start" size="large" style={{ marginBottom: 16 }}>
          <Input.Search
            allowClear
            onChange={e => setSearchText(e.target.value)}
            placeholder="Search by name"
            style={{ width: 300 }}
          />
          <Select
            onChange={value => setSelectedClientState(value)}
            placeholder="Filter by state"
            style={{ width: 200 }}
            value={selectedClientState}
          >
            {clientStates.map(state => (
              <Option key={state} value={state}>
                {state === 'all' ? 'All States' : state}
              </Option>
            ))}
          </Select>
          <Space>
            <Switch checked={showSingleAgent} onChange={setShowSingleAgent} />
            <span>Show Single Agent</span>
          </Space>
        </Space>
      </div>
      <Table
        columns={[
          {
            title: 'Name',
            dataIndex: 'friendlyName',
            key: 'name',
          },
          {
            title: 'Status',
            key: 'status',
            dataIndex: 'clientState',
          },
          {
            title: 'Is Single Agent',
            key: 'isSingleAgent',
            dataIndex: 'isSingleAgent',
            render: (text: boolean) => (text ? 'Yes' : 'No'),
          },
        ]}
        dataSource={filteredClients}
        loading={loading}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => handleRowClick(record),
          };
        }}
        rowKey="id"
      />

      <Button onClick={showNewClientModal} type="primary">
        Create New Client
      </Button>
      <Button onClick={() => setIsSingleAgentModalOpen(true)} type="primary">
        Create Single Agent
      </Button>
      <CreateClient
        apiUrl={apiUrl}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <CreateSingleAgent
        apiUrl={apiUrl}
        isOpen={isSingleAgentModalOpen}
        setIsOpen={setIsSingleAgentModalOpen}
      />
    </div>
  );
};

export default ClientsPage;
