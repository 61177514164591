import { Form, Input, Modal, Select } from 'antd';
import Title from 'antd/es/typography/Title';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../UserContext';

interface CreateClientProps {
  apiUrl: string;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function CreateClient({
  apiUrl,
  isModalOpen,
  setIsModalOpen,
}: CreateClientProps) {
  const [form] = Form.useForm();
  const [friendlyName, setFriendlyName] = useState<string>('');
  const [crmType, setCrmType] = useState<string | null>(null);
  const user = useContext(UserContext);

  const [timezone, setTimeZone] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleFriendlyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFriendlyName(e.target.value);
  };

  const handleCrmTypeChange = (value: string) => {
    setCrmType(value);
  };

  const handleNewClientOk = async () => {
    try {
      await form.validateFields();
    } catch {
      return false;
    }
    if (user == null) {
      return;
    }
    const response = await fetch(`${apiUrl}/api/clients/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.accessToken}`,
      },
      body: JSON.stringify({
        friendlyName: friendlyName,
        crmType: crmType,
        timezone: timezone,
      }),
    });
    setIsModalOpen(false);
    const data = await response.json();
    navigate(`/client/${data.id}/verify`);
  };

  const handleNewClientCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      onCancel={handleNewClientCancel}
      onOk={form.submit}
      open={isModalOpen}
      title={<Title level={2}>Create New Client</Title>}
    >
      <Form autoComplete="off" form={form} onFinish={handleNewClientOk}>
        <Form.Item
          label="Client Name"
          name="client_name"
          rules={[{ required: true, message: 'Client Name is required.' }]}
        >
          <Input onChange={handleFriendlyNameChange} />
        </Form.Item>
        <Form.Item
          label="CRM Type"
          name="crm_type"
          rules={[{ required: true, message: 'CRM Type is required.' }]}
        >
          <Select
            onChange={handleCrmTypeChange}
            options={[
              { value: 'fub', label: <span>Follow Up Boss</span> },
              { value: 'sierra', label: <span>Sierra</span> },
              { value: 'kvcore', label: <span>BoldTrail</span> },
            ]}
            placeholder="Select CRM"
            style={{ width: '100%' }}
          ></Select>
        </Form.Item>
        <Form.Item
          label="Timezone"
          name="timezone"
          rules={[{ required: true, message: 'Timezone is required.' }]}
        >
          <Select
            onChange={setTimeZone}
            options={[
              { value: 'America/New_York', label: 'America/New_York' },
              { value: 'America/Chicago', label: 'America/Chicago' },
              { value: 'America/Denver', label: 'America/Denver' },
              { value: 'America/Los_Angeles', label: 'America/Los_Angeles' },
              { value: 'America/Anchorage', label: 'America/Anchorage' },
              { value: 'America/Phoenix', label: 'America/Phoenix' },
              { value: 'Pacific/Honolulu', label: 'Pacific/Honolulu' },
            ]}
            placeholder="Select timezone"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

interface CreateSingleAgentProps {
  isOpen: boolean;
  apiUrl: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface CreateSingleAgentForm {
  crmType: string;
  apiKey: string;
  email?: string;
}

export function CreateSingleAgent({
  isOpen,
  apiUrl,
  setIsOpen,
}: CreateSingleAgentProps) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const onSubmit = async (values: CreateSingleAgentForm) => {
    if (user == null) {
      return;
    }
    try {
      const response = await fetch(`${apiUrl}/api/clients/single-agent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({
          crmType: values.crmType,
          apiKey: values.apiKey,
          email: values.email,
        }),
      });

      if (!response.ok) {
        throw new Error(await response.text());
      }

      const data = await response.json();
      setIsOpen(false);
      form.resetFields();
      navigate(`/client/${data.id}/verify-single-agent`);
    } catch (error) {
      console.error('Error creating client:', error);
      Modal.error({
        title: 'Error',
        content: `Failed to create client. ${error}`,
      });
    }
  };
  return (
    <Modal
      onCancel={() => setIsOpen(false)}
      onOk={form.submit}
      open={isOpen}
      title={<Title level={2}>Create Single Agent Client</Title>}
    >
      <Form
        autoComplete="off"
        form={form}
        initialValues={{ crmType: 'fub' }}
        onFinish={onSubmit}
      >
        <Form.Item
          label="CRM Type"
          name="crmType"
          rules={[{ required: true, message: 'CRM Type is required.' }]}
        >
          <Select
            options={[
              { value: 'fub', label: <span>Follow Up Boss</span> },
              { value: 'sierra', label: <span>Sierra</span> },
              { value: 'kvcore', label: <span>BoldTrail</span> },
            ]}
            placeholder="Select CRM"
            style={{ width: '100%' }}
          ></Select>
        </Form.Item>
        <Form.Item
          label="Api Key"
          name="apiKey"
          rules={[{ required: true, message: 'Api Key is required.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.crmType !== currentValues.crmType
          }
        >
          {({ getFieldValue }) =>
            getFieldValue('crmType') === 'kvcore' ? (
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Email is required for BoldTrail.',
                  },
                ]}
              >
                <Input placeholder="Enter agent's BoldTrail email" />
              </Form.Item>
            ) : null
          }
        </Form.Item>
      </Form>
    </Modal>
  );
}
