import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useMsal } from '@azure/msal-react';
import { BrowserAuthError } from '@azure/msal-browser';

// User types
type UserType = {
  userID: string;
  name: string;
  accessToken: string;
} | null;

// Create a context for the user with a default value
const UserContext = createContext<UserType>(null);

// Create a provider component
const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { accounts, instance } = useMsal();
  const [user, setUser] = useState<UserType>(null);

  const acquireToken = async () => {
    try {
      instance.setActiveAccount(accounts[0]);
      await instance.initialize();
      const response = await instance.acquireTokenSilent({
        scopes: ['User.Read'],
        account: accounts[0],
      });
      return response.accessToken;
    } catch (error: any) {
      if (error instanceof BrowserAuthError) {
        // Token expired, try to acquire a new one interactively
        const response = await instance.acquireTokenPopup({
          scopes: ['User.Read'],
          account: accounts[0],
        });
        return response.accessToken;
      }
      throw error;
    }
  };

  useEffect(() => {
    void acquireToken();
  }, [accounts, instance]);

  useEffect(() => {
    console.log('accounts', accounts);
    if (accounts.length > 0) {
      acquireToken()
        .then(accessToken => {
          setUser({
            userID: accounts[0].localAccountId,
            name: accounts[0].username,
            accessToken,
          });
        })
        .catch(e => {
          console.log(e);
        });
      // instance.setActiveAccount(accounts[0]);
      // Extract needed user details from accounts
    }
  }, [accounts]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
export type { UserType };
