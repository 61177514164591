import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { Divider, Spin, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AlertBanner } from '../../../../AlertBanner';
import { SignInButton } from '../../../../SignInButton';
import { AgentSection } from '../../../../components/client/agent-section';
import { AppointmentsConfig } from '../../../../components/client/appointment-types-config';
import { ClientConfig } from '../../../../components/client/client-config';
import { ClientFeatures } from '../../../../components/client/client-features';
import { CredentialConfig } from '../../../../components/client/external-api-config';
import { StagesConfig } from '../../../../components/client/stages-config';
import {
  AppointmentTypeSettings,
  ClientConfig as ClientConfigType,
  ClientFeatures as ClientFeatureType,
  FeatureValue,
  Stage,
} from '../../../../types';
import { UserContext } from '../../../../UserContext';

export function ClientSettingsPage({ apiUrl }: { apiUrl: string }) {
  const { clientId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>();
  const [config, setConfig] = useState<ClientConfigType | undefined>();
  const [features, setFeatures] = useState<ClientFeatureType | undefined>();
  const [zillowCredentials, setZillowCredentials] = useState<
    CredentialConfig[]
  >([]);
  const [realtorCredentials, setRealtorCredentials] = useState<
    CredentialConfig[]
  >([]);
  const [stages, setStages] = useState<Stage[] | undefined>(undefined);
  const [appointmentTypes, setAppointmentTypes] = useState<
    AppointmentTypeSettings[] | undefined
  >(undefined);
  const user = useContext(UserContext);

  const fetchClientSettings = async () => {
    if (user == null) {
      return;
    }
    try {
      setLoading(true);
      setError(undefined);

      const response = await fetch(`${apiUrl}/api/settings/${clientId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(
          `Error fetching client settings: ${await response.text()}`,
        );
      }
      const results = await response.json();
      setConfig(results.config);
      setFeatures(results.features);
      setZillowCredentials(results.zillow_credentials);
      setRealtorCredentials(results.realtor_credentials);
      setStages(results.stages);
      setAppointmentTypes(results.appointment_types);
    } catch (error) {
      setError(`${error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void fetchClientSettings();
  }, [user?.accessToken]);
  const toggleFeature = async (feature: keyof ClientFeatureType) => {
    if (!user || !clientId) {
      return;
    }

    try {
      setError(undefined);
      const newValue =
        features?.[feature] === FeatureValue.ALL
          ? FeatureValue.DISABLED
          : FeatureValue.ALL;

      const response = await fetch(
        `${apiUrl}/api/settings/${clientId}/features/${feature}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.accessToken}`,
          },
          body: JSON.stringify({ feature_value: newValue }),
        },
      );

      if (!response.ok) {
        throw new Error(`Failed to update feature: ${await response.text()}`);
      }

      setFeatures(prevFeatures => {
        if (prevFeatures === undefined) {
          return prevFeatures;
        }
        return { ...prevFeatures, [feature]: newValue };
      });
    } catch (error) {
      setError(`Error updating feature: ${error}`);
      // Revert the optimistic update
      // await fetchClientSettings();
    }
  };
  return (
    <>
      <UnauthenticatedTemplate>
        <SignInButton loginType="popup" />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <AlertBanner error={error} setError={setError} />
        <Typography.Title level={2}>
          {config?.friendly_name || 'Client'} Configuration
        </Typography.Title>
        {loading ? (
          <Spin />
        ) : config && features && user ? (
          <>
            <ClientConfig
              accessToken={user.accessToken || ''}
              apiUrl={apiUrl}
              clientId={clientId || ''}
              config={config}
              features={features}
              onConfigChange={fetchClientSettings}
              realtorCredentials={realtorCredentials}
              setError={setError}
              zillowCredentials={zillowCredentials}
            />
            <Divider />
            <ClientFeatures features={features} toggleFeature={toggleFeature} />
            <Divider />
            <StagesConfig
              accessToken={user.accessToken || ''}
              apiUrl={apiUrl}
              clientId={clientId || ''}
              setError={setError}
              setStages={setStages}
              stages={stages || []}
            />
            <Divider />
            <AppointmentsConfig
              accessToken={user.accessToken || ''}
              apiUrl={apiUrl}
              appointments={appointmentTypes || []}
              clientId={clientId || ''}
              setAppointments={setAppointmentTypes}
              setError={setError}
            />
            <Divider />
            <AgentSection
              accessToken={user.accessToken || ''}
              apiUrl={apiUrl}
              clientConfigId={clientId || ''}
              clientFeatures={features}
              clientId={config.client_id}
              setError={setError}
            />
          </>
        ) : (
          <span>Error Reach out to eng or refresh</span>
        )}
      </AuthenticatedTemplate>
    </>
  );
}
