import { Button, Flex, List, Spin, Tag, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../UserContext';
import { Agent } from '../../types';

const { Text } = Typography;

interface ApiAppointment {
  id: string;
  title: string;
  description?: string;
  start_time: string;
  end_time?: string;
  appointment_type?: string;
  lead_source_id?: string;
  outcome?: string;
  status?: string;
  lead?: {
    first_name: string;
    last_name: string;
    phone?: string;
    email?: string;
  };
}

interface AppointmentsProps {
  agent: Agent;
  apiUrl: string;
}

export const Appointments = ({
  agent,
  apiUrl,
}: AppointmentsProps): JSX.Element => {
  const user = useContext(UserContext);
  const [appointments, setAppointments] = useState<
    ApiAppointment[] | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchAppointments = async (agent: Agent) => {
    try {
      setLoading(true);
      if (!user?.accessToken) {
        console.error('No access token available');
        return;
      }

      const url = `${apiUrl}/api/agents/${agent.id}/appointments`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch appointments: ${response.status}`);
      }

      const jsonData = await response.json();
      setAppointments(jsonData);
    } catch (err) {
      console.error('An error occurred while fetching appointments:', err);
      setAppointments([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAppointments(agent).then().catch(console.log);
  }, [agent]);

  const formatDate = (dateString: string) => {
    if (!dateString) {
      return 'No date set';
    }
    return new Date(dateString).toLocaleString('en', {
      timeZone: agent.timezone || 'UTC',
      dateStyle: 'medium',
      timeStyle: 'short',
    });
  };

  // Sort appointments by start time (closest future appointments first, then past appointments)
  const sortedAppointments = appointments?.slice().sort((a, b) => {
    const now = new Date();
    const dateA = new Date(a.start_time);
    const dateB = new Date(b.start_time);

    // Both in the future
    if (dateA > now && dateB > now) {
      return dateA.getTime() - dateB.getTime();
    }
    // Both in the past
    if (dateA <= now && dateB <= now) {
      return dateB.getTime() - dateA.getTime(); // Most recent past first
    }
    // One past, one future
    return dateA > now ? -1 : 1; // Future appointments come first
  });

  return (
    <div>
      <Spin spinning={loading}>
        <Flex style={{ height: '70vh', overflowY: 'auto' }} vertical>
          <Button onClick={() => void fetchAppointments(agent)}>Refresh</Button>

          {appointments && appointments.length === 0 && (
            <Text style={{ margin: '20px 0', textAlign: 'center' }}>
              No appointments found for this agent.
            </Text>
          )}

          <List
            dataSource={sortedAppointments}
            itemLayout="vertical"
            renderItem={appointment => {
              const isPast = new Date(appointment.start_time) < new Date();
              return (
                <List.Item
                  extra={
                    <div style={{ textAlign: 'right' }}>
                      {isPast ? (
                        <Tag color="orange">Past</Tag>
                      ) : (
                        <Tag color="blue">Upcoming</Tag>
                      )}
                      {appointment.status && (
                        <Tag
                          color={
                            appointment.status === 'Completed'
                              ? 'green'
                              : 'default'
                          }
                        >
                          {appointment.status}
                        </Tag>
                      )}
                    </div>
                  }
                  style={{
                    background: '#f5f5f5',
                    padding: '16px',
                    borderRadius: '8px',
                    margin: '8px 0',
                  }}
                >
                  <List.Item.Meta
                    description={
                      <div>
                        <Text>Start: {formatDate(appointment.start_time)}</Text>
                        {appointment.end_time && (
                          <div>
                            <Text>End: {formatDate(appointment.end_time)}</Text>
                          </div>
                        )}
                        {appointment.lead && (
                          <div>
                            <Text>
                              With: {appointment.lead.first_name}{' '}
                              {appointment.lead.last_name}
                            </Text>
                          </div>
                        )}
                      </div>
                    }
                    title={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Text strong>{appointment.title || 'Appointment'}</Text>
                      </div>
                    }
                  />
                  {appointment.description && (
                    <div style={{ marginTop: '10px' }}>
                      <Text type="secondary">Details:</Text>
                      <div
                        style={{
                          background: '#fff',
                          padding: '8px',
                          borderRadius: '4px',
                          marginTop: '4px',
                        }}
                      >
                        {typeof appointment.description === 'object'
                          ? JSON.stringify(appointment.description)
                          : appointment.description}
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      marginTop: '10px',
                      fontSize: '12px',
                      color: '#888',
                    }}
                  >
                    <div>Appointment ID: {appointment.id}</div>
                    {appointment.lead_source_id && (
                      <div>Lead ID: {appointment.lead_source_id}</div>
                    )}
                    {appointment.appointment_type && (
                      <div>Type: {appointment.appointment_type}</div>
                    )}
                    {appointment.outcome && (
                      <div>
                        Outcome:{' '}
                        {typeof appointment.outcome === 'object'
                          ? JSON.stringify(appointment.outcome)
                          : appointment.outcome}
                      </div>
                    )}
                  </div>
                </List.Item>
              );
            }}
          />
        </Flex>
      </Spin>
    </div>
  );
};
