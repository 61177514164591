// src/pages/client/[client_id]/client-settings/components/ClientConfig.tsx
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Typography,
} from 'antd';
import { useState } from 'react';
import {
  ClientConfig as ClientConfigType,
  ClientFeatures as ClientFeatureType,
  FeatureValue,
} from '../../types';
import { CredentialConfig, ExternalApiConfig } from './external-api-config';

interface ClientConfigInput {
  masterConfig: string;
  zillowCredentials?: CredentialConfig[];
  realtorCredentials?: CredentialConfig[];
  sisuEnabled: boolean;
  lexicon: string;
  defaultAssistantName: string;
  dispositionInstructions?: string;
  accountManager?: string;
}

export function ClientConfig(props: {
  config: ClientConfigType;
  features: ClientFeatureType;
  onConfigChange: () => Promise<void>;
  zillowCredentials: CredentialConfig[];
  realtorCredentials: CredentialConfig[];
  apiUrl: string;
  clientId: string;
  accessToken: string;
  setError: (error: string | undefined) => void;
}) {
  const [form] = Form.useForm();
  const [newTag, setNewTag] = useState('');
  const [loading, setLoading] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const handleFinish = async (values: ClientConfigInput) => {
    setLoading(true);
    props.setError(undefined);
    try {
      console.log(values);
      // Get only the touched fields
      const touchedFields = form.isFieldsTouched()
        ? Object.fromEntries(
            Object.entries(values).filter(([key]) => form.isFieldTouched(key)),
          )
        : {};

      if (Object.keys(touchedFields).length === 0) {
        props.setError('No fields were modified');
        return;
      }

      const response = await fetch(
        `${props.apiUrl}/api/settings/${props.clientId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${props.accessToken}`,
          },
          body: JSON.stringify(touchedFields),
        },
      );

      if (!response.ok) {
        throw new Error(`Failed to update settings: ${await response.text()}`);
      }

      await props.onConfigChange();
    } catch (error) {
      props.setError(`Error saving client settings: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div style={{ marginTop: '20px' }}>
        <Typography.Title level={4}>Navigation Links</Typography.Title>
        <Typography.Paragraph>
          <Typography.Link href={'/client'}>Client List</Typography.Link> |
          <Typography.Link href={`/onboardingNew/${props.config.client_id}`}>
            Agent Onboarding
          </Typography.Link>{' '}
          |
          <Typography.Link href={`/agentSms/${props.config.client_id}`}>
            Agent SMS/Call
          </Typography.Link>
        </Typography.Paragraph>
      </div>
      <Form
        disabled={loading}
        form={form}
        initialValues={{
          // masterConfig: props.config.client_state,
          masterConfig:
            props.features.PRODUCTION === FeatureValue.DISABLED
              ? 'off'
              : props.features.PRODUCTION === FeatureValue.ALL
                ? 'on'
                : 'agents',
          sisuEnabled: props.config.sisu_enabled,
          lexicon: props.config.lexicon,
          defaultAssistantName: props.config.default_assistant_name,
          dispositionInstructions: props.config.disposition_instructions,
          zillowCredentials: props.zillowCredentials,
          realtorCredentials: props.realtorCredentials,
          doNotProcessLeadTags: props.config.do_not_process_lead_tags,
          accountManager: props.config.account_manager,
        }}
        labelCol={{ span: 4 }}
        layout="horizontal"
        onFinish={handleFinish}
        onValuesChange={() => {
          setIsTouched(true);
        }}
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
        }}
      >
        <Form.Item
          label="Client Activation"
          name="masterConfig"
          wrapperCol={{ span: 8 }}
        >
          <Select placeholder="Select activation status">
            <Select.Option value="off">OFF</Select.Option>
            <Select.Option value="on">ON</Select.Option>
          </Select>
        </Form.Item>
        <Divider />

        <Form.Item
          label="Sisu Integration"
          name="sisuEnabled"
          tooltip="Sisu integration is an additional offering for clients that also license Sisu's reporting offering. Checking this box causes HouseWhisper to update the deal card after an appointment is met which causes the deal to correctly show as met in the deal pipeline."
          valuePropName="checked"
          wrapperCol={{ span: 8 }}
        >
          <Checkbox>Enable Sisu Integration</Checkbox>
        </Form.Item>

        <Form.Item
          label="Lexicon"
          name="lexicon"
          tooltip="Enter unique words or locations specific to this partner (this is fed into transcription to help with spelling issues)"
        >
          <Input.TextArea placeholder="Enter unique words or locations" />
        </Form.Item>

        <Form.Item
          label="Default Assistant Name"
          name="defaultAssistantName"
          wrapperCol={{ span: 8 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Account Manager"
          name="accountManager"
          wrapperCol={{ span: 8 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={
            <span style={{ whiteSpace: 'pre-wrap' }}>
              Custom Disposition{'\n'}Instructions
            </span>
          }
          name="dispositionInstructions"
          tooltip={`Insert custom instructions for dispositions here. This will replace the default options in the disposition dropdown.
Make sure to start with "Replace the 1. Met 2. No Show 3.Rescheduled options with" Example:
Custom instructions: Replace the 1. Met2. No Show3. Rescheduled options with 1. No show 2. Working with buyers 3. Listing obtained`}
        >
          <Input.TextArea placeholder="Replace the 1. Met2. No Show3. Rescheduled options with 1. No show 2. Working with buyers 3. Listing obtained" />
        </Form.Item>

        <Divider />

        <ExternalApiConfig
          description="The Zillow integration allows HW to capture new inbound phone calls from zillow. We import the call into the CRM, Generate a Bio using the call, and track tasks and appointments that were discussed on the call. Please manually test the login credentials to ensure they are correct."
          title="Zillow Config"
          type="zillow"
        />
        <Divider />

        <ExternalApiConfig
          description="The Realtor integration allows HW to capture new inbound phone calls from Realtor. We import the call into the CRM, Generate a Bio using the call, and track tasks and appointments that were discussed on the call. Please manually test the login credentials to ensure they are correct."
          title="Realtor Config"
          type="realtor"
        />
        <Divider />
        <div style={{ textAlign: 'left', marginBottom: '16px' }}>
          <Typography.Title level={4}>Exclusion Tags</Typography.Title>
          <Typography.Paragraph>
            Tags used by the brokerage to label trash leads or recruiting leads
            that should be excluded from phone processing
          </Typography.Paragraph>

          <Form.List name="doNotProcessLeadTags">
            {(fields, { add, remove }) => (
              <div>
                <Space
                  align="baseline"
                  direction="vertical"
                  style={{ marginBottom: 8 }}
                >
                  {fields.map(({ key, name }) => (
                    <div key={key}>
                      <span>
                        {form.getFieldValue(['doNotProcessLeadTags', name])}
                      </span>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </div>
                  ))}
                </Space>
                <div style={{ display: 'flex', marginBottom: '10px' }}>
                  <Input
                    onChange={e => setNewTag(e.target.value)}
                    placeholder="Tag"
                    style={{ maxWidth: '200px' }}
                    value={newTag}
                  />
                  <PlusOutlined
                    onClick={() => {
                      if (newTag.trim() === '') {
                        return;
                      }
                      add(newTag);
                      setNewTag('');
                    }}
                    style={{ marginLeft: '5px' }}
                  />
                </div>
              </div>
            )}
          </Form.List>
        </div>

        <Form.Item>
          <Button
            disabled={!isTouched}
            htmlType="submit"
            loading={loading}
            type="primary"
          >
            Save Client Configuration
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
