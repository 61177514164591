import { Button, Flex, List, Spin, Tag, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../UserContext';
import { Agent } from '../../types';

const { Text, Title } = Typography;

interface NativeReminder {
  id: string;
  agent_id: string;
  description: string;
  datetime: string;
  do_not_send: boolean;
  sequence_number?: number;
  hardcoded_message?: string;
  repeat_until?: string;
  repeat_interval?: string;
  created_at: string;
  updated_at: string;
}

interface RemindersProps {
  agent: Agent;
  apiUrl: string;
}

export const Reminders = ({ agent, apiUrl }: RemindersProps): JSX.Element => {
  const user = useContext(UserContext);
  const [reminders, setReminders] = useState<NativeReminder[] | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState<boolean>(true);

  const fetchReminders = async (agent: Agent) => {
    try {
      setLoading(true);
      if (!user?.accessToken) {
        console.error('No access token available');
        return;
      }

      const url = `${apiUrl}/api/agents/${agent.id}/reminders`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch reminders: ${response.status}`);
      }

      const jsonData = await response.json();
      setReminders(jsonData);
    } catch (err) {
      console.error('An error occurred while fetching reminders:', err);
      setReminders([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReminders(agent).then().catch(console.log);
  }, [agent]);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString('en', {
      timeZone: agent.timezone || 'UTC',
      dateStyle: 'medium',
      timeStyle: 'short',
    });
  };

  // Sort reminders in descending order (newest first)
  const sortedReminders = reminders
    ?.slice()
    .sort(
      (a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime(),
    );

  return (
    <div>
      <Spin spinning={loading}>
        <Flex style={{ height: '70vh', overflowY: 'auto' }} vertical>
          <Button onClick={() => void fetchReminders(agent)}>Refresh</Button>

          {reminders && reminders.length === 0 && (
            <Text style={{ margin: '20px 0', textAlign: 'center' }}>
              No reminders found for this agent.
            </Text>
          )}

          <List
            dataSource={sortedReminders}
            itemLayout="vertical"
            renderItem={reminder => (
              <List.Item
                extra={
                  <div style={{ textAlign: 'right' }}>
                    {reminder.do_not_send && <Tag color="red">Do Not Send</Tag>}
                    {reminder.repeat_interval && (
                      <Tag color="blue">
                        {reminder.repeat_interval.toUpperCase()}
                      </Tag>
                    )}
                  </div>
                }
                style={{
                  background: '#f5f5f5',
                  padding: '16px',
                  borderRadius: '8px',
                  margin: '8px 0',
                }}
              >
                <List.Item.Meta
                  description={
                    <div>
                      <Text>
                        Scheduled for: {formatDate(reminder.datetime)}
                      </Text>
                      {reminder.repeat_until && (
                        <div>
                          <Text>
                            Repeats until: {formatDate(reminder.repeat_until)}
                          </Text>
                        </div>
                      )}
                    </div>
                  }
                  title={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Text strong>{reminder.description}</Text>
                      <Text type="secondary">ID: {reminder.id}</Text>
                    </div>
                  }
                />
                {reminder.hardcoded_message && (
                  <div style={{ marginTop: '10px' }}>
                    <Text type="secondary">Message:</Text>
                    <div
                      style={{
                        background: '#fff',
                        padding: '8px',
                        borderRadius: '4px',
                        marginTop: '4px',
                      }}
                    >
                      {reminder.hardcoded_message}
                    </div>
                  </div>
                )}
                <div
                  style={{ marginTop: '10px', fontSize: '12px', color: '#888' }}
                >
                  <div>Created: {formatDate(reminder.created_at)}</div>
                  <div>Last Updated: {formatDate(reminder.updated_at)}</div>
                  {reminder.sequence_number !== undefined && (
                    <div>Sequence Number: {reminder.sequence_number}</div>
                  )}
                </div>
              </List.Item>
            )}
          />
        </Flex>
      </Spin>
    </div>
  );
};
