export const fetchCrmLink = async (
  apiUrl: string,
  clientId: string | undefined,
  leadSourceId: string | undefined,
  accessToken: string | undefined,
): Promise<string> => {
  try {
    const crm_url = `${apiUrl}/api/configs/${clientId}/crm_link`;
    const crm_response = await fetch(crm_url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const crm_jsonData = await crm_response.json();

    if (crm_jsonData && crm_jsonData.crm_link) {
      return crm_jsonData.crm_link.replace(
        '{lead_source_id}',
        leadSourceId || '0',
        leadSourceId || '0',
      );
    }
  } catch (error) {
    console.error('Error fetching CRM link:', error);
  }
  return '';
};
