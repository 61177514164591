import { ClientConfig } from '@/types';
import {
  Alert,
  Button,
  Card,
  Descriptions,
  message,
  Modal,
  Space,
  Spin,
  Typography,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../../../UserContext';

const { Title } = Typography;

interface VerificationStatus {
  isRecordingEnabled: boolean;
  isSmsEnabled: boolean;
  clientState?: string;
}

export default function VerifySingleAgentPage({ apiUrl }: { apiUrl: string }) {
  const { clientId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [client, setClient] = useState<ClientConfig | null>(null);
  const [verificationStatus, setVerificationStatus] =
    useState<VerificationStatus | null>(null);
  const [backfillRunning, setBackfillRunning] = useState(false);
  const [manualOverrideConfirmed, setManualOverrideConfirmed] = useState(false);
  const user = useContext(UserContext);

  const handleVerify = async () => {
    setLoading(true);
    try {
      if (user == null) {
        return;
      }
      const response = await fetch(
        `${apiUrl}/api/clients/${clientId}/verify-single-agent`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.accessToken}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error('Failed to verify');
      }

      const data = await response.json();
      setVerificationStatus(data);
      setManualOverrideConfirmed(false); // Reset override when verification status changes

      if (data.isSmsEnabled) {
        await message.success(
          'Verification successful. You can now proceed with backfill.',
        );
      } else {
        await message.warning('SMS is not enabled. Backfill cannot proceed.');
      }
    } catch (error) {
      await message.error('Failed to verify client. Please try again later.');
      console.error('Error verifying client:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleBackfill = async () => {
    setBackfillRunning(true);
    try {
      if (user == null) {
        return;
      }
      const response = await fetch(
        `${apiUrl}/api/clients/${clientId}/backfill`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.accessToken}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error('Failed to start backfill');
      }
      await message.success('Backfill started successfully');
    } catch (error) {
      await message.error('Failed to start backfill. Please try again later.');
      console.error('Error starting backfill:', error);
    } finally {
      setBackfillRunning(false);
    }
  };

  const handleManualOverride = () => {
    Modal.confirm({
      title: 'Manual Override Confirmation',
      content:
        'SMS capability is not enabled, which may affect the backfill process. Are you sure you want to proceed with the backfill anyway?',
      okText: 'Yes, proceed',
      cancelText: 'Cancel',
      onOk: () => {
        setManualOverrideConfirmed(true);
        message.warning(
          'Manual override enabled. You can now start the backfill process.',
        );
      },
    });
  };

  useEffect(() => {
    const fetchClient = async () => {
      setLoading(true);
      try {
        if (user == null) {
          return;
        }
        const response = await fetch(`${apiUrl}/api/clients/${clientId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.accessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch client');
        }
        const data = await response.json();
        setClient(data);
        void handleVerify();
      } catch (error) {
        await message.error('Failed to load client. Please try again later.');
        console.error('Error fetching client:', error);
      } finally {
        setLoading(false);
      }
    };
    void fetchClient();
  }, [apiUrl, clientId, user?.accessToken]);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!client) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Title level={4}>Client not found</Title>
      </div>
    );
  }

  return (
    <div style={{ padding: '24px' }}>
      <Card>
        <Descriptions bordered title="Client Information">
          <Descriptions.Item label="Name">
            {client.friendly_name}
          </Descriptions.Item>
          <Descriptions.Item label="CRM Type">
            {client.crm_type}
          </Descriptions.Item>
        </Descriptions>

        {verificationStatus && (
          <div style={{ marginTop: '24px' }}>
            <Title level={4}>Verification Results</Title>
            <Descriptions bordered>
              <Descriptions.Item label="Recording Enabled">
                {verificationStatus.isRecordingEnabled ? 'Yes' : 'No'}
              </Descriptions.Item>
              <Descriptions.Item label="SMS Enabled">
                {verificationStatus.isSmsEnabled ? 'Yes' : 'No'}
              </Descriptions.Item>
              {manualOverrideConfirmed && (
                <Descriptions.Item label="Manual Override">
                  <span style={{ color: 'orange', fontWeight: 'bold' }}>
                    Enabled
                  </span>
                </Descriptions.Item>
              )}
            </Descriptions>

            {!verificationStatus.isSmsEnabled && !manualOverrideConfirmed && (
              <Alert
                description="SMS capability is required for the backfill process. Please ensure SMS is enabled in your CRM before proceeding."
                message="SMS is not enabled"
                showIcon
                style={{ marginTop: '16px' }}
                type="warning"
              />
            )}

            {manualOverrideConfirmed && (
              <Alert
                description="You have chosen to override the SMS requirement. The backfill process may not work correctly without SMS capability."
                message="Manual Override Enabled"
                showIcon
                style={{ marginTop: '16px' }}
                type="warning"
              />
            )}
          </div>
        )}

        <div style={{ marginTop: '24px' }}>
          <Space>
            <Button loading={loading} onClick={handleVerify} type="primary">
              Verify Connection
            </Button>

            {verificationStatus &&
              !verificationStatus.isSmsEnabled &&
              !manualOverrideConfirmed && (
                <Button danger onClick={handleManualOverride} type="primary">
                  Manual Override
                </Button>
              )}

            <Button
              disabled={
                !verificationStatus?.isSmsEnabled && !manualOverrideConfirmed
              }
              loading={backfillRunning}
              onClick={handleBackfill}
              type="primary"
            >
              Start Backfill
            </Button>
          </Space>
        </div>
      </Card>
    </div>
  );
}
