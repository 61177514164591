import { ClientConfig } from '@/types';
import {
  Button,
  Card,
  Descriptions,
  message,
  Select,
  Spin,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../../../UserContext';

dayjs.extend(utc);

const { Title } = Typography;
const { Option } = Select;

// Define available pipeline stages
const PIPELINE_STAGES = [
  { value: 'extract_data', label: 'Extract Data' },
  { value: 'recordings', label: 'Recordings' },
  { value: 'process_raw', label: 'Process Raw' },
  { value: 'update', label: 'Update' },
  { value: 'smart_tasks', label: 'Smart Tasks' },
];

export default function BackfillStatusPage({ apiUrl }: { apiUrl: string }) {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [rerunLoading, setRerunLoading] = useState<boolean>(false);
  const [taskMessage, setTaskMessage] = useState('');
  const [client, setClient] = useState<ClientConfig | null>(null);
  const [createdAt, setCreatedAt] = useState<string | null>(null);
  const [updatedAt, setUpdatedAt] = useState<string | null>(null);
  const [jobMetadata, setJobMetadata] = useState<any>(null);
  const [backfillRunning, setBackfillRunning] = useState(true);
  const [backfillFailed, setBackfillFailed] = useState(false);
  const [selectedStage, setSelectedStage] = useState('extract_data');
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const [backfillStatus, setBackfillStatus] = useState<any>(null);

  const formatDate = (dateString: string | null) => {
    return dateString
      ? dayjs.utc(dateString).local().format('MMMM D, YYYY h:mm A')
      : 'N/A';
  };

  const fetchClient = async () => {
    setLoading(true);
    try {
      if (user == null) {
        return;
      }
      const response = await fetch(`${apiUrl}/api/clients/${clientId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch client');
      }
      const data = await response.json();
      setClient(data);
      setCreatedAt(formatDate(data.created_at));
      setUpdatedAt(formatDate(data.updated_at));
    } catch (error) {
      await message.error('Failed to load client. Please try again later.');
      console.error('Error fetching client:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRerunBackfill = async () => {
    try {
      setRerunLoading(true);
      if (!user || !client?.id) {
        return;
      }

      const response = await fetch(
        `${apiUrl}/api/clients/${client.id}/backfill?target=${selectedStage}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.accessToken}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error('Failed to rerun backfill');
      }

      // Reset status flags
      setBackfillRunning(true);
      setBackfillFailed(false);
      await message.success('Backfill started successfully');

      // Refresh status immediately
      await fetchBackfillStatus();
    } catch (error) {
      console.error('Error rerunning backfill:', error);
      await message.error('Failed to rerun backfill. Please try again.');
    } finally {
      setRerunLoading(false);
    }
  };

  const fetchBackfillStatus = async () => {
    if (!client?.client_id) {
      return;
    }
    try {
      if (user == null) {
        return;
      }
      const response = await fetch(
        `${apiUrl}/api/Batches/${client.client_id}/status`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.accessToken}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error('Failed to fetch status');
      }
      const data = await response.json();
      setBackfillStatus(data);
      console.log('Backfill failed:', data);
      // Check for failed state
      if (data.state === 'failed') {
        setBackfillRunning(false);
        setBackfillFailed(true);
        setTaskMessage(
          'Backfill process failed. You can rerun it from a specific stage.',
        );

        if (data.error_details) {
          setErrorDetails(data.error_details);
        }
      }
      // Check for completed state
      else if (data.state === 'completed') {
        setBackfillRunning(false);
        setBackfillFailed(false);
        await fetchClient();
      }
      // Check for other non-running states
      else if (
        data.state !== 'running' &&
        data.state !== 'preparing' &&
        data.state !== 'active' &&
        data.state !== 'started'
      ) {
        setBackfillRunning(false);
      }

      // Store job metadata if available
      if (data.job_metadata) {
        setJobMetadata(data.job_metadata);
        // Pre-select the last used stage for convenience
        if (data.job_metadata.target_stage) {
          setSelectedStage(data.job_metadata.target_stage);
        }
      }

      setCreatedAt(formatDate(data.created_at));
      setUpdatedAt(formatDate(data.updated_at));
    } catch (error) {
      console.error('Error fetching backfill status:', error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      void fetchBackfillStatus();
    }, 300000); // 300 seconds
    void fetchBackfillStatus(); // Initial fetch

    return () => {
      clearInterval(intervalId); // Cleanup on unmount
    };
  }, [apiUrl, client?.client_id]);

  useEffect(() => {
    if (backfillRunning) {
      return;
    }
    if (client && client.client_state === 'active') {
      navigate(`/client/${client.id}/settings`);
    } else {
      console.log('backfillRunning else ', backfillRunning);
      setTaskMessage('Something went wrong in the backfill');
    }
  }, [client, backfillRunning, backfillFailed]);

  useEffect(() => {
    void fetchClient();
  }, [apiUrl, clientId]);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!client) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Title level={4}>Client not found</Title>
      </div>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      <Card>
        <Descriptions
          column={1}
          title={`Backfill Status for ${client.friendly_name}`}
        >
          <Descriptions.Item label="Internal Name">
            {client.name}
          </Descriptions.Item>
          <Descriptions.Item label="Client Id">
            {client.client_id}
          </Descriptions.Item>
          <Descriptions.Item label="Backfill Status">
            {backfillRunning ? 'Running' : 'Finished with errors'}
          </Descriptions.Item>
          <Descriptions.Item label="Job Started">{createdAt}</Descriptions.Item>
          <Descriptions.Item label="Last Checked">
            {updatedAt}
          </Descriptions.Item>
          <Descriptions.Item label="Message">{taskMessage}</Descriptions.Item>

          {!backfillRunning && jobMetadata && jobMetadata.job_id && (
            <Descriptions.Item label="Log Path">
              {`${jobMetadata.job_id}/extract_data/stderr.txt`}
            </Descriptions.Item>
          )}
        </Descriptions>
        {(!backfillRunning || backfillFailed) && (
          <div style={{ marginTop: '20px' }}>
            <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
              <Select
                onChange={value => setSelectedStage(value)}
                style={{ width: 200 }}
                value={selectedStage}
              >
                {PIPELINE_STAGES.map(stage => (
                  <Option key={stage.value} value={stage.value}>
                    {stage.label}
                  </Option>
                ))}
              </Select>
              <Button
                loading={rerunLoading}
                onClick={handleRerunBackfill}
                type="primary"
              >
                Rerun Backfill
              </Button>
            </div>
            <div
              style={{
                marginTop: '8px',
                fontSize: '12px',
                color: 'rgba(0, 0, 0, 0.45)',
              }}
            >
              Select the stage where you want to restart the backfill process
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}
